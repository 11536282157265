import { ZhLanguageDefinition } from "../data/ZhLanguageDefinition"
import { db, UnitState } from "../Database/db"
import { ItemHistoryElement } from "../Database/HistoryState"
import { LanguageDefinition, LanguageUnitDefinition } from "../Types/LanguageDefinition"
import { TestDefinitionMapType, UnitDefinitionMap, UnitDefinitionMapType } from "./UnitDefinitionMap"
import { ZhBaseUnitTestDefinition, ZhLanguageUnitTypes } from "../Types/Zh/LanguageDefinition";
import { EmptyBelowFold } from "../Components/TestFolds/Empty"
import { ZhBaseUnitGlyphToMeaningAboveFold, ZhBaseUnitGlyphToMeaningBelowFold, ZhBaseUnitGlyphToSpokenReadingAboveFold, ZhBaseUnitMeaningToWrittenGlyphAboveFold, ZhBaseUnitType } from "../Components/TestFolds/ZhBaseUnit"
import dayjs from "dayjs"
import { ExtraUnitsDefinitionType } from "../Loaders/LanguageDefinitionLoader"
import { YieldKnownStructures } from "../Utility/ProcessedTextFromSegmentedOutput"
import { SimpleItemCache } from "../Utility/UnitHistoryAccessor"
import { ZhCompositeUnitSentenceBaseAboveFold, ZhCompositeUnitSentenceBaseBelowFold, ZhCompositeUnitType } from "../Components/TestFolds/ZhCompositeUnit"


export interface LanguageConfiguration<LanguageUnitType> {
    Id: number
    Label: string

    Definition: LanguageDefinition<LanguageUnitType>
    Units: UnitDefinitionMapType
    UnitTypes: LanguageUnitType[]
    PrimaryTestDefinitionId: number
    BaseUnitTestDefinitions: TestDefinitionMapType<ZhBaseUnitType>
    CompositeUnitTestDefinitions: TestDefinitionMapType<ZhCompositeUnitType>

    SelectNext: (UnitDefinitions: UnitDefinitionMapType, Parameters: SelectUnitParameters) => Promise<NextItemContainer>
}

enum ZhCompositeTestDefinitions {
    SentenceBase
}

export const ZhLanguageConfiguration: LanguageConfiguration<ZhLanguageUnitTypes> = {
    Id: 1,
    Label: '中文',

    Definition: ZhLanguageDefinition,
    Units: UnitDefinitionMap,
    UnitTypes: [ZhLanguageUnitTypes.Radical, ZhLanguageUnitTypes.Hanzi, ZhLanguageUnitTypes.Word],
    PrimaryTestDefinitionId: ZhBaseUnitTestDefinition.GlyphToMeaning,
    BaseUnitTestDefinitions: {},
    CompositeUnitTestDefinitions: {
        [ZhCompositeTestDefinitions.SentenceBase]: {
            id: ZhCompositeTestDefinitions.SentenceBase,

            Label: "SentenceBase",

            AboveFold: ZhCompositeUnitSentenceBaseAboveFold,
            BelowFold: ZhCompositeUnitSentenceBaseBelowFold,

            Reintroduce: false,
            UserIndicatesIfCorrect: true,
        }
    },
    SelectNext: ZhSelectNextItems
}

function SortHistoryElementByLanguageDefinitionKey(Key: string) {
    return (a: ItemHistoryElement, b: ItemHistoryElement) => {
        var unitA = ZhLanguageDefinition[a.UnitKey].Keys[Key]
        var unitB = ZhLanguageDefinition[b.UnitKey].Keys[Key]

        return unitA - unitB;

    }
}
function SortHistoryElementByLastSeen() {
    return (a: ItemHistoryElement, b: ItemHistoryElement) => {
        return a.LastSeen?.getTime()!! - b.LastSeen?.getTime()!!
    }
}

function SortHistoryElementByScore() {
    return (a: ItemHistoryElement, b: ItemHistoryElement) => {
        return a.Score - b.Score
    }
}

function SelectNew(type: number, UnitDefinitions: UnitDefinitionMapType) {
    return (element: ItemHistoryElement) => {
        return !element.Introduced && element.UnitTypes.includes(type) &&
            element.TestDefinitionId == UnitDefinitions[type].ParentTestDefinitionId
    }
}

function SelectReview(type: number, UnitDefinitions: UnitDefinitionMapType) {
    return (element: ItemHistoryElement) => {
        return element.Introduced && element.UnitTypes.includes(type) &&
            element.TestDefinitionId == UnitDefinitions[type].ParentTestDefinitionId
    }
}




export enum OrderSelectedUnits {
    Default,
    Frequency
}

export interface SelectUnitParameters {
    Counts: {
        New: { [type: number]: number }
        Review: number
    },
    OrderBy: OrderSelectedUnits,
    TestDefinitions: number[]
}

export interface SelectedUnits {
    New: ItemHistoryElement[],
    Review: ItemHistoryElement[]
}

type ItemTests = ItemHistoryElement[]

type SelectedItems = { New: ItemTests[], Review: ItemTests[] }

export interface NextItemContainer {
    Base: SelectedItems,
    Composite: SelectedItems
}

export async function ZhSelectNextItems(UnitDefinitions: UnitDefinitionMapType, Parameters: SelectUnitParameters): Promise<NextItemContainer> {

    const UnitStateMap: { [UnitId: number]: UnitState } = {}

    const UnitStates = await db.UnitState.toArray()
    UnitStates.map(us => {
        UnitStateMap[us.UnitId] = us
    })

    const UnlockedItemHistory = (await db.ItemHistory.toArray()).filter(h => h.Unlocked)
    const ItemTestCache: { [key: string]: { [test: number]: ItemHistoryElement } } = {};
    (UnlockedItemHistory.map(h => {
        if (ItemTestCache[h.UnitKey] == undefined)
            ItemTestCache[h.UnitKey] = {}
        ItemTestCache[h.UnitKey][h.TestDefinitionId] = h
    }))

    // reap orphaned test definitions - if the parent test definition is introduced, the child test definitions will not be reintroduced
    // force set Introduced = true so when test is re-enabled, can enter review pool
    const now = new Date()
    for (const parentUnit of UnlockedItemHistory.filter(h => h.TestDefinitionId == ZhLanguageConfiguration.PrimaryTestDefinitionId)) {

        for (const [_, h] of Object.entries(ItemTestCache[parentUnit.UnitKey])) {
            if (parentUnit.Introduced && h.Introduced == false) {
                h.Introduced = true
                h.Due = now

                console.log(`reap orphaned td ${h.UnitKey}:${h.TestDefinitionId}`)
                await db.ItemHistory.update(h.Id, h)
            }
        }
    }


    const New: ItemHistoryElement[] = []
    const Review: ItemHistoryElement[] = []

    if (!UnitStateMap[ZhLanguageUnitTypes.Radical].Completed) {

        const NewRadicals = UnlockedItemHistory
            .filter(SelectNew(ZhLanguageUnitTypes.Radical, UnitDefinitions))
            .sort(SortHistoryElementByLanguageDefinitionKey("Radical"))
        NewRadicals.slice(0, Parameters.Counts.New[ZhLanguageUnitTypes.Radical]).map(h => New.push(h))

    } else {

        const NewHanzi = UnlockedItemHistory
            .filter(SelectNew(ZhLanguageUnitTypes.Hanzi, UnitDefinitions))
            .sort(SortHistoryElementByLanguageDefinitionKey("Hanzi"))
        NewHanzi.slice(0, Parameters.Counts.New[ZhLanguageUnitTypes.Hanzi]).map(h => New.push(h))

        const NewWords = UnlockedItemHistory
            .filter((element: ItemHistoryElement) => {
                return !element.Introduced && element.UnitTypes.includes(ZhLanguageUnitTypes.Word) && element.UnitTypes.length == 1 &&
                    element.TestDefinitionId == UnitDefinitions[ZhLanguageUnitTypes.Word].ParentTestDefinitionId
            })
            .sort(SortHistoryElementByLanguageDefinitionKey("Word"))
        NewWords.slice(0, Parameters.Counts.New[ZhLanguageUnitTypes.Word]).map(h => New.push(h))

    }

    // add due review items
    UnlockedItemHistory.filter(h => h.Introduced == true && dayjs(h.Due).diff(new Date(), 'day') == 0
        && h.TestDefinitionId == UnitDefinitions[ZhLanguageUnitTypes.Word].ParentTestDefinitionId)
        .slice(0, Parameters.Counts.Review)
        .map(h => Review.push(h))

    const newNew = New.map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            if (ItemTestCache[h.UnitKey][td] == undefined) {
                console.log(`ERROR ${h.UnitKey}:${td} undefined ???`)
            }
            tests.push(ItemTestCache[h.UnitKey][td])
        })
        return tests
    })

    const newReviwe = Review.map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            tests.push(ItemTestCache[h.UnitKey][td])
        })
        return tests
    })

    console.log(newNew)
    console.log(newReviwe)

    return {
        Base: {
            New: newNew,
            Review: newReviwe
        },
        Composite: { New: [], Review: [] }
    }
}

export const UpdateUnitState = async (LanguageDefinitionConfiguration: LanguageConfiguration<any>, ExtraLanguageDefinition: ExtraUnitsDefinitionType) => {

    for (const unitType of LanguageDefinitionConfiguration.UnitTypes) {
        const UnitId = unitType
        const startTime = new Date()
        const unitState = await db.UnitState.where('UnitId').equals(UnitId).first()

        const unitDefn = UnitDefinitionMap[UnitId]
        const allUnits = await db.ItemHistory.filter(h => h.UnitTypes.includes(UnitId)).and(h => h.TestDefinitionId == unitDefn.ParentTestDefinitionId).toArray()

        const unlocked = await unitDefn.UnlockUnitOn()

        const completed = allUnits.every(h => h.Introduced == true)


        if (completed) {
            unitState!!.Completed = true
            console.log(`unit id ${UnitId} completed`)
        }

        if (unlocked && unitState?.Locked) {
            unitState!!.Locked = false
            console.log(`unit id ${UnitId} unlocked`)

            // only change unlocked state if not previously unlocked 
            await db.ItemHistory.filter(h => h.UnitTypes.includes(UnitId)).modify(async (h: ItemHistoryElement) => {
                if (!h.Unlocked)
                    h.Unlocked = !unitDefn.ItemsLocked
            })

        }

        if (unlocked && unitDefn.ItemsLocked) {

            const SimpleUnitCachee: { [key: string]: ItemHistoryElement } = {};
            // (await db.ItemHistory.where("TestDefinitionId").equals(unitDefn.ParentTestDefinitionId).toArray()).map((h: ItemHistoryElement) => unitHistoryCache[h.UnitKey] = h)

            const AllUnitHistory = (await db.ItemHistory.toArray())
            const UnitTestCache: { [key: string]: { [test: number]: ItemHistoryElement } } = {};
            (AllUnitHistory.map(h => {
                if (UnitTestCache[h.UnitKey] == undefined)
                    UnitTestCache[h.UnitKey] = {}
                UnitTestCache[h.UnitKey][h.TestDefinitionId] = h
            }))

            AllUnitHistory.filter(h => h.TestDefinitionId == unitDefn.ParentTestDefinitionId).map((h: ItemHistoryElement) => SimpleUnitCachee[h.UnitKey] = h)


            const toUnlock = []

            for (const historyElem of allUnits) {

                var ldu: LanguageUnitDefinition<any>

                if (LanguageDefinitionConfiguration.Definition[historyElem.UnitKey]) {
                    ldu = LanguageDefinitionConfiguration.Definition[historyElem.UnitKey]
                } else if (ExtraLanguageDefinition[historyElem.UnitKey]) {
                    ldu = ExtraLanguageDefinition[historyElem.UnitKey]
                }


                const toUnitItem = unitDefn.HistoryElementToUnit(historyElem.UnitKey, ldu!!)
                const unlockItem = await unitDefn.UnlockItemOn(toUnitItem, SimpleUnitCachee)

                if (unlockItem && (historyElem.Unlocked == false)) {

                    // ensure all tests are unlocked based on parent test state
                    const allTests = Object.entries(UnitTestCache[historyElem.UnitKey])
                    for (const [_, h] of allTests) {
                        h.Unlocked = true
                        toUnlock.push(h)
                        await db.ItemHistory.update(h.Id, h)

                    }

                }

            }

            console.log(`unlocked ${toUnlock.length} items in unit ${UnitId}`)

        }

        await db.UnitState.update(UnitId, unitState!!)

        const endTime = new Date()

        console.log(`process unit ${UnitId} ${new Date(endTime.getTime() - startTime.getTime()).getTime()} elapsed`)

    }

    const ItemCache = await SimpleItemCache()

    const allText = await db.TextRefStore.toArray()
    console.log("processing text...")

    var unlockedCount = 0

    for (const text of allText) {
        const knownStructures = YieldKnownStructures(text, ItemCache).filter(s => s.Type == 'Paragraph' || s.Type == 'Sentence')
        for (const s of knownStructures) {
            if (ItemCache[s.Ref].Unlocked == false) {
                ItemCache[s.Ref].Unlocked = true;
                await db.ItemHistory.update(ItemCache[s.Ref].Id, ItemCache[s.Ref])
                unlockedCount++;
            }
        }
    }

    console.log(`unlocked ${unlockedCount} items from textref store`)

}