import { ZhItemTypeTestConfiguration } from "./Config/ItemTypeTestConfig";
import { LanguageUnitItemConfiguration, ZhUnitConfig } from "./Config/UnitConfig";
import { UnitTestInterfaceConfiguration, ZhUnitTestInterfaceConfig } from "./Config/UnitTestInterfaceConfig";
import { LanguageTextMap, ZhLanguageText } from "./Text";
import { ItemTypeTestConfigurationMap } from "./Types";

export type LanguageDefinitionConfiguration = {
    ItemTypeTestConfiguration: ItemTypeTestConfigurationMap,
    UnitItemListing: LanguageUnitItemConfiguration,
    UnitTestInterfaceConfiguration: UnitTestInterfaceConfiguration,
    Text: LanguageTextMap 

}

export const ZhLanguageDefinition : LanguageDefinitionConfiguration = {
    ItemTypeTestConfiguration: ZhItemTypeTestConfiguration,
    UnitItemListing: ZhUnitConfig,
    UnitTestInterfaceConfiguration: ZhUnitTestInterfaceConfig,
    Text: ZhLanguageText
}