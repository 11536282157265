import React, { Component, Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { StyledLogin } from './StyledLogin'
import { Login } from './Login'
import { Logout } from './Logout'
import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';
import { StyledLogout, ErrorBoundary } from './StyledLogout';

export default class ApiAuthorizationRoutes extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ErrorBoundary>
        <Routes>
          <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
          <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
          <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
          <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
          <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
          <Route path={ApplicationPaths.UserData} element={loginAction(LoginActions.UserData)} />
          <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
          <Route path={ApplicationPaths.LogOutCallback} element={logoutAction(LogoutActions.LogoutCallback)} />
          <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />
        </Routes>
      </ErrorBoundary>);
  }
}

function loginAction(name, settings) {
  return (<StyledLogin action={name} settings={settings}></StyledLogin>);
}

function logoutAction(name, settings) {
  return (<StyledLogout action={name} settings={settings}></StyledLogout>);
}
