import Dexie, { Transaction } from 'dexie';
import { RoundRecord } from '../Views/RoundView';
import { UnitDefinitionMap, UnitDefinition } from '../Config/UnitDefinitionMap';
import { LanguageFundamentalType, ItemHistoryElement } from './HistoryState';
import { RoundHistoryItem } from './RoundHistoryState';
import { DefaultUnitControllerParams, SettingsState, SettingsStorageItem } from './SettingsState';
import { MessageRecord } from './MessageRecord';
import { ZhLanguageDefinition } from '../data/ZhLanguageDefinition';
import { ProcessedText } from '../Types/ProcessedText';
import { ZhLanguageConfiguration } from '../Config/LanguageDefinitionConfiguration';
import { TextRefType, TextStructure } from './TextStore';

export interface UnitState {
    id?: number
    UnitId: number
    Locked: boolean,
    Completed: boolean,

}

export class AppDexie extends Dexie {

    UnitState!: Dexie.Table<UnitState>

    ItemHistory!: Dexie.Table<ItemHistoryElement>

    RoundHistory!: Dexie.Table<RoundRecord>

    Settings!: Dexie.Table<SettingsStorageItem>

    Messages!: Dexie.Table<MessageRecord>

    Texts!: Dexie.Table<ProcessedText>

    TextStructures!: Dexie.Table<TextStructure>
    TextRefStore!: Dexie.Table<TextRefType>

    constructor() {
        super('Database');
        this.version(1).stores({
            UnitState: '++id, UnitId, Unlocked',
            History: '++Id, UnitId, ItemId, TestDefinitionId',
            ItemHistory: '++Id, UnitKey, TestDefinitionId',
            RoundHistory: '++Id, UnitId, RoundGuid',
            Settings: '++Id, LanguageId',
            Messages: '++Id',
            Texts: '++Id',
            TextStructures: '++Id, Ref',
            TextRefStore: '++Id, Ref'
        });
    }
}

export const db = new AppDexie()


async function Initialise(tx: Transaction) {

    // console.log("initialising database")

    // for (const [k, lud] of Object.entries(ZhLanguageDefinition)) {

    //     const unlockedByDefault = Object.entries(UnitDefinitionMap).reduce((prev, [_, ud]) => {

    //         if (prev == true) {
    //             return true
    //         }
    //         else {
    //             if (lud.Types.includes(ud.LanguageUnitType)) {
    //                 return !ud.UnitLocked && !ud.ItemsLocked

    //             } else {
    //                 return prev
    //             }
    //         }


    //     }, false)

    //     for (const [i, td] of Object.entries(ZhLanguageConfiguration.BaseUnitTestDefinitions)) {
    //         await tx.table("ItemHistory").put({
    //             TestDefinitionId: td.id,

    //             UnitKey: k,
    //             FundamentalType: LanguageFundamentalType.Base,
    //             UnitTypes: lud.Types,
    //             Introduced: false,
    //             Unlocked: unlockedByDefault,

    //             LastSeen: undefined,
    //             Due: undefined,
    //             Stability: 0,
    //             Difficulty: 0,
    //             Reps: 0,
    //             Lapses: 0,
    //             LearningState: 0,

    //             Score: 0,
    //             Record: []
    //         } as ItemHistoryElement);
    //     }



    // }

    // console.log(await tx.table("ItemHistory").count() + " entries added to NewHistory")


    // // init unit state

    // for (const [k, ud] of Object.entries(UnitDefinitionMap)) {
    //     var unitId = parseInt(k)

    //     var unitState = await (tx.table("UnitState").where('UnitId').equals(unitId)).first()

    //     if (unitState == undefined) {

    //         await tx.table("UnitState").put({
    //             id: unitId,
    //             UnitId: unitId,
    //             Locked: ud.UnitLocked,
    //             Completed: false,
    //         });

    //         // Object.entries(ud.TestDefinitions).map(([_, td], i) => {
    //         //     ud.Items.map(async (item : any) => {

    //         //         await tx.table("History").add({
    //         //             UnitId: ud.UnitId,
    //         //             ItemId: ud.GetItemId(item),
    //         //             TestDefinitionId: td.id,

    //         //             Introduced: false,
    //         //             Unlocked: false,

    //         //             LastSeen: undefined,
    //         //             Score: 0,
    //         //             Record: []
    //         //         })
    //         //     })
    //         // })

    //     }

    //     // set default settings for each unit
    // }

    // var defaultSettings = {
    //     LanguageId: ZhLanguageConfiguration.Id,
    //     Params: DefaultUnitControllerParams(ZhLanguageConfiguration)
    // }

    // defaultSettings.Params.OrderRound = undefined
    // await tx.table("Settings").add(defaultSettings)


};

db.on("populate", async (tx: Transaction) => {
    await Initialise(tx)
})