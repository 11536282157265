import styled, { css } from "styled-components"

export const ButtonDisplay = styled.div`
display: flex;
gap: 12px;
padding-top: 24px;
flex-direction: column;
`


export const ControllerModeContainer = styled.div`

display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
gap: 24px;
width: 100%;
flex-grow: 1;
`

export const ButtonSubDisplay = styled.div`
flex-wrap: wrap;
display: flex; 
gap: 12px;
flex-direction: row; width: 100%; 
justify-content: space-around;

`


export const InnerTile = styled.div<{ Small?: boolean }>`
    box-shadow: 3px 3px 9px #eadfd5;
    text-align: center;
    overflow-wrap: anywhere;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding: 24px;

    ${props => props.Small ?
        css`
        min-width: 40px;
        height: 40px;
        padding: 12px;
        `
        :
        css`
        @media (width <  453px) {
            flex-basis: calc(100%);
        }
    
        @media (width > 453px) {
            flex-basis: calc(50% - 12px);
        }
    
        @media (width > 800px) {
            flex-basis: calc(33% - 24px);
        }
    
        @media (width > 1000px) {
            flex-basis: calc(25% - 24px);
        }
    
        @media (width > 1200px) {
            flex-basis: calc(20% - 24px);
        }
        `
    }


`
export const RowLayout = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    flex-wrap: wrap;
`

export const ColumnLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
`