/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SelectReviewByOption } from '../models/SelectReviewByOption';
import { HttpFile } from '../http/http';

export class SelectItemConfiguration {
    'selectReviewBy': SelectReviewByOption;
    'testDefinitions': Array<number>;
    'reviewItems': number;
    'newItems': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "selectReviewBy",
            "baseName": "selectReviewBy",
            "type": "SelectReviewByOption",
            "format": ""
        },
        {
            "name": "testDefinitions",
            "baseName": "testDefinitions",
            "type": "Array<number>",
            "format": "double"
        },
        {
            "name": "reviewItems",
            "baseName": "reviewItems",
            "type": "number",
            "format": "double"
        },
        {
            "name": "newItems",
            "baseName": "newItems",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return SelectItemConfiguration.attributeTypeMap;
    }

    public constructor() {
    }
}



