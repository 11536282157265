/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class PartialTextNode {
    'primary'?: string;
    'target': string;
    'type': PartialTextNodeTypeEnum;
    'label'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "primary",
            "baseName": "Primary",
            "type": "string",
            "format": ""
        },
        {
            "name": "target",
            "baseName": "Target",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "Type",
            "type": "PartialTextNodeTypeEnum",
            "format": ""
        },
        {
            "name": "label",
            "baseName": "Label",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PartialTextNode.attributeTypeMap;
    }

    public constructor() {
    }
}


export enum PartialTextNodeTypeEnum {
    Node = 'Node'
}

