/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ItemHistory {
    'score': number;
    'learningState': number;
    'lapses': number;
    'reps': number;
    'difficulty': number;
    'stability': number;
    'due': Date;
    'lastSeen': Date;
    'introducedOn': Date;
    'unlocked': boolean;
    'introduced': boolean;
    'testDefinitionId': number;
    'itemTypeIds': Array<number>;
    'unitId': number;
    'key': string;
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "score",
            "baseName": "score",
            "type": "number",
            "format": "double"
        },
        {
            "name": "learningState",
            "baseName": "learningState",
            "type": "number",
            "format": "double"
        },
        {
            "name": "lapses",
            "baseName": "lapses",
            "type": "number",
            "format": "double"
        },
        {
            "name": "reps",
            "baseName": "reps",
            "type": "number",
            "format": "double"
        },
        {
            "name": "difficulty",
            "baseName": "difficulty",
            "type": "number",
            "format": "double"
        },
        {
            "name": "stability",
            "baseName": "stability",
            "type": "number",
            "format": "double"
        },
        {
            "name": "due",
            "baseName": "due",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "lastSeen",
            "baseName": "lastSeen",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "introducedOn",
            "baseName": "introducedOn",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "unlocked",
            "baseName": "unlocked",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "introduced",
            "baseName": "introduced",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "testDefinitionId",
            "baseName": "testDefinitionId",
            "type": "number",
            "format": "double"
        },
        {
            "name": "itemTypeIds",
            "baseName": "itemTypeIds",
            "type": "Array<number>",
            "format": "double"
        },
        {
            "name": "unitId",
            "baseName": "unitId",
            "type": "number",
            "format": "double"
        },
        {
            "name": "key",
            "baseName": "key",
            "type": "string",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return ItemHistory.attributeTypeMap;
    }

    public constructor() {
    }
}

