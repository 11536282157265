/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class DashboardResponse {
    'studiedTodayCheck': boolean;
    'studiedYesterdayCheck': boolean;
    'currentStreak': number;
    'longestStreak': number;
    'minutesStudiedToday': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "studiedTodayCheck",
            "baseName": "studiedTodayCheck",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "studiedYesterdayCheck",
            "baseName": "studiedYesterdayCheck",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "currentStreak",
            "baseName": "currentStreak",
            "type": "number",
            "format": "double"
        },
        {
            "name": "longestStreak",
            "baseName": "longestStreak",
            "type": "number",
            "format": "double"
        },
        {
            "name": "minutesStudiedToday",
            "baseName": "minutesStudiedToday",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return DashboardResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

