import { useContext } from "react"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { TextStyle } from "../App"
import { ContentTile } from "../Components/ModeTile"
import { ColumnLayout, RowLayout } from "../Styles/Styles"

export const GradeView: React.FC = (props) => {
    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: [`grades`],
        queryFn: () => {

            return api.GradeState().then(UnwrapApiCall)

        }
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    const response = data!!;

    const gradeMap : Record<number, Record<number, {progress: number, total: number}>> = {}

    response.forEach((grade) => {
        if (gradeMap[grade.grade] == undefined) {
            gradeMap[grade.grade] = {}
        }
        gradeMap[grade.grade][grade.itemTypeId] = {progress: grade.progress, total: grade.total}
    })


    return <>
    
        <ContentTile>
            <ColumnLayout>
                {Object.keys(gradeMap).map((grade) => {
                    return <RowLayout>
                        <TextStyle size={2} weight="bold">{grade}</TextStyle>
                        {Object.keys(gradeMap[parseInt(grade)]).map((itemType) => {
                            return <TextStyle size={2} >{gradeMap[parseInt(grade)][parseInt(itemType)].progress}/{gradeMap[parseInt(grade)][parseInt(itemType)].total}</TextStyle>
                        })}
                    </RowLayout>
            })}
            </ColumnLayout>
        </ContentTile>
    </>
}