import { useEffect, useState } from "react";
import { AppStyle, MainColumn, TextStyle, TileContainer } from "../App";
import { ContentTile, StyledButton } from "../Components/ModeTile";
import { ColumnLayout, ControllerModeContainer, RowLayout } from "../Styles/Styles";
import { useContext } from "react";
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ApiKeyAuthentication, DefaultApi, IsomorphicFetchHttpLibrary, RegisterAccount, ServerConfiguration } from "../api";
import { DefaultApiContext, ExecuteApi, UnwrapApiCall, WrapCallWithApiResult } from "../Contexts/Api/DefaultApiContext";
import { ResponseType, RootApiContext } from "../Contexts/Api/RootApiContext";


export const RegistrationView: React.FC = props => {



    const [settings, setSettings] = useState<{ ApiServer: string } | undefined>(undefined)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(false)

    const ClickRegister = () => {

        const conf = {
            baseServer: new ServerConfiguration(settings?.ApiServer!!, {}),
            authMethods: { "api_key" : new ApiKeyAuthentication("") },
            middleware: [],
            httpApi: new IsomorphicFetchHttpLibrary()
        }
    
        const Api = new DefaultApi(conf)

        var registerAccount = new RegisterAccount()
        registerAccount.username = username
        registerAccount.password = password

        setLoading(true)


        ExecuteApi((acc : RegisterAccount) => { return Api.register(acc) }, registerAccount).then(resp => {
            if (resp.responseType == ResponseType.Success) {
                setCompleted(resp.object); setLoading(false) 
            } else {
                setCompleted(false); setLoading(false) 
            }
        })

    }

    useEffect(() => {
        if (settings == undefined)
          fetch("/config.json", { headers: { 'Conten-Type': 'application/json' } }).then(async (response) => {
            setSettings(await response.json())
          })
      }, [settings])

    return <>

            <AppStyle>
                <MainColumn>
                    <TileContainer>
                        <ContentTile>
                            <ControllerModeContainer>
                                <ColumnLayout>
                                    <RowLayout>
                                        <TextStyle size={0.75}>Username</TextStyle>
                                        <input type="text" onChange={e => setUsername(e.target.value)}></input>
                                    </RowLayout>
                                    <RowLayout>
                                        <TextStyle size={0.75}>Password</TextStyle>
                                        <input type="password" onChange={e => setPassword(e.target.value)} />
                                    </RowLayout>
                                    <StyledButton onClick={ClickRegister}>Register</StyledButton>

                                    {loading && <TextStyle>Registering...</TextStyle>}
                                    {completed && <TextStyle>Registered.</TextStyle>}
                                </ColumnLayout>
                            </ControllerModeContainer>
                        </ContentTile>
                    </TileContainer>
                </MainColumn>
            </AppStyle>
    </>
}