import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { TextStyle } from "../App";
import { Collapse } from "../Components/Collapse";
import { Modal } from "../Components/Modal";
import { ContentTile, StyledButton } from "../Components/ModeTile";
import { NextItemContainer, OrderSelectedUnits, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration";
import { DictionaryQueryTile } from "../Displays/ProcessedTextDisplay";
import { ColumnLayout, RowLayout } from "../Styles/Styles";
import { ProcessedText } from "../Types/ProcessedText";
import { ItemHistoryFromProcessedText, ProcessedTextFromSegmentationOutput } from "../Utility/ProcessedTextFromSegmentedOutput";
import { LearnView } from "./LearnView";
import { RoundControllerParams } from "./RoundView";
import { ApiContext } from "../Contexts/Api/ApiContext";
import { ResponseType } from "../Contexts/Api/RootApiContext";
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext";
import { TextStructureStudyView } from "../Displays/TextStructureDisplay";

export const TextHelperModal: React.FC<{ Ref: string, Text: string, OnClose: () => any }> = props => {

    enum OptionsMode {
        Select,
        Translate,
        Explain,
        Break,
        Lesson
    }

    const [mode, setMode] = useState(OptionsMode.Select)

    return <>
        <Modal OnClose={() => { setMode(OptionsMode.Select); props.OnClose() }}>
            <ContentTile>
                {mode != OptionsMode.Select && <StyledButton onClick={() => setMode(OptionsMode.Select)}>Back</StyledButton>}
                {mode == OptionsMode.Select && <>
                    <ColumnLayout>
                        {/* <TextStyle>{props.Text}</TextStyle> */}

                        <StyledButton onClick={() => setMode(OptionsMode.Translate)}>Translate</StyledButton>
                        <StyledButton onClick={() => setMode(OptionsMode.Explain)}>Explain</StyledButton>
                        {/* <StyledButton onClick={() => setMode(OptionsMode.Break)}>Break</StyledButton> */}
                        <StyledButton onClick={() => setMode(OptionsMode.Lesson)}>Study</StyledButton>
                    </ColumnLayout>
                </>}
                {mode == OptionsMode.Explain && <>
                    <ExplainView Text={props.Text} />
                </>}
                {mode == OptionsMode.Translate && <>
                    <TranslateView Text={props.Text} />
                </>}
                {mode == OptionsMode.Break && <>
                    <BreakdownView Text={props.Text} />
                </>}
                {mode == OptionsMode.Lesson && <>
                    {/* <SegmentLessonView Text={props.Text} /> */}
                    <TextStructureStudyView Mode={'SentenceBreakdown'} TextRef={props.Ref} OnClose={() => { props.OnClose() }} />
                </>}

            </ContentTile>
        </Modal>
    </>
}

// const SegmentLessonView: React.FC<{ Text: string }> = props => {

//     const api = useContext(ApiContext)
//     const [nextUnits, setNextUnits] = useState<NextUnitContainer | undefined>(undefined)

//     const { isPending, error, data } = useQuery({
//         queryKey: [`breakdown#${props.Text}`],
//         queryFn: () => {
//             var segmentTextRequest = {
//                 textPairs: [{ target: props.Text }],
//                 translateMissing: false,
//                 translateMissingUnit: false

//             }
//             return api.SegmentSentences(segmentTextRequest).then(resp => { if (resp.responseType == ResponseType.Success) return resp.object; })
//         }
//     })

//     useEffect(() => {
//         if (!isPending && nextUnits == undefined) {
//             const segmentationOutput = data!!.data;
//             ProcessedTextFromSegmentationOutput("", segmentationOutput)
//                 .then(pt => {
//                     var count = 0;
//                     pt.ItemHistoryContainers.map(ihcs => ihcs.SegmentUnits.map(su => { count += su.BaseItems.length }))
//                     return ItemHistoryFromProcessedText(pt, {
//                         Counts: {
//                             New: { 1: count, 2: count },
//                             Review: 0
//                         },
//                         OrderBy: OrderSelectedUnits.Default,
//                         TestDefinitions: [ZhLanguageConfiguration.PrimaryTestDefinitionId]
//                     })
//                 })
//                 .then(hs => setNextUnits(hs))
//         }
//     }, [nextUnits, isPending, data])

//     if (isPending || nextUnits == undefined) return <>Loading...</>
//     if (error) return <>An error has occurred: {error.message}</>

//     const roundParams: RoundControllerParams = {
//         IntroduceNew: true,
//         IntroduceCount: nextUnits.New.length,
//         RoundSize: 0,
//         PromoteOnNth: 1,
//         FailOnNth: 1,
//         ShowUntilCorrect: true,
//         ShuffleOnIncorrect: false,
//         SaveResults: true,
//         TimeLimit: 0,
//         TestDefinitions: [ZhLanguageConfiguration.PrimaryTestDefinitionId]
//     }

//     return <>
//         <LearnView
//             OnExit={() => { }}
//             Data={{
//                 Params: roundParams,
//                 NextUnits: nextUnits,
//                 LanguageConfiguration: ZhLanguageConfiguration,
//                 RoundRecords: []
//             }}
//             State={{
//                 UseSubUnit: false,
//                 SubUnitId: 0,
//                 RetryRound: false,
//                 RoundRecordId: 0
//             }}
//             SetMenu={(a: any) => { }}
//             Retry={(r) => { }}
//         />

//     </>
// }

const TranslateView: React.FC<{ Text: string }> = props => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: [`translate#${props.Text}`],
        queryFn: () => {
            var queryObject = {
                query: props.Text
            }
            return api.Translate(queryObject).then(UnwrapApiCall)
        }
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ContentTile>

            <ColumnLayout>
                <div>{props.Text}</div>
                <hr />
                {data.split("\n").map(s => <div>{s}</div>)}
            </ColumnLayout>
        </ContentTile>
    </>

}

const ExplainView: React.FC<{ Text: string }> = props => {
    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: [`explain#${props.Text}`],
        queryFn: () => {
            var queryObject = {
                query: props.Text
            }
            return api.Explain(queryObject).then(UnwrapApiCall)
        }
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ContentTile>
            <ColumnLayout>
                {data.split("\n").map(s => <div>{s}</div>)}
            </ColumnLayout>
        </ContentTile>
    </>

}


const BreakdownView: React.FC<{ Text: string }> = props => {
    const api = useContext(ApiContext)

    enum ViewState {
        Breakdown,
        Save
    }

    const [viewState, setViewState] = useState(ViewState.Breakdown)

    const { isPending, error, data } = useQuery({
        queryKey: [`breakdown#${props.Text}`],
        queryFn: () => {
            var segmentTextRequest = {
                textPairs: [{ target: props.Text }],
                translateMissing: false,
                translateMissingUnit: false

            }
            return api.SegmentSentences(segmentTextRequest).then(UnwrapApiCall)
        }
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    const segmentationOutput = data!!.data;

    return <>
        <ContentTile>
            {viewState == ViewState.Breakdown && <ColumnLayout>
                {segmentationOutput.map(o => {
                    if (o.output.length > 0) {
                        return <Collapse Label={o.input.target} Collapsed={false}>
                            <StyledButton onClick={() => setViewState(ViewState.Save)}>Save</StyledButton>
                            <RowLayout>
                                {o.output.map((p, i) => {
                                    return <DictionaryQueryTile key={i} Text={p.unit} />
                                })}
                            </RowLayout>
                        </Collapse>
                    } else {
                        return <></>
                    }
                })}
            </ColumnLayout>}
            {viewState == ViewState.Save && <ColumnLayout>
                {/* <ProcessTextIntoUnitHistory SegmentationOutput={segmentationOutput} /> */}
            </ColumnLayout>}
        </ContentTile>
    </>

}
