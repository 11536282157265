/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GeneratedTextStyle } from '../models/GeneratedTextStyle';
import { TextDifficulty } from '../models/TextDifficulty';
import { TextLength } from '../models/TextLength';
import { TextPromptType } from '../models/TextPromptType';
import { TextType } from '../models/TextType';
import { HttpFile } from '../http/http';

export class GenerateTextRequest {
    'label': string;
    'difficulty': TextDifficulty;
    'length': TextLength;
    'style': GeneratedTextStyle;
    'type': TextType;
    'promptType': TextPromptType;
    'focusDialogue': boolean;
    'themePrompt': string;
    'briefDescription': string;
    'deriveFrom': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "label",
            "baseName": "Label",
            "type": "string",
            "format": ""
        },
        {
            "name": "difficulty",
            "baseName": "Difficulty",
            "type": "TextDifficulty",
            "format": ""
        },
        {
            "name": "length",
            "baseName": "Length",
            "type": "TextLength",
            "format": ""
        },
        {
            "name": "style",
            "baseName": "Style",
            "type": "GeneratedTextStyle",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "Type",
            "type": "TextType",
            "format": ""
        },
        {
            "name": "promptType",
            "baseName": "PromptType",
            "type": "TextPromptType",
            "format": ""
        },
        {
            "name": "focusDialogue",
            "baseName": "FocusDialogue",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "themePrompt",
            "baseName": "ThemePrompt",
            "type": "string",
            "format": ""
        },
        {
            "name": "briefDescription",
            "baseName": "BriefDescription",
            "type": "string",
            "format": ""
        },
        {
            "name": "deriveFrom",
            "baseName": "DeriveFrom",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GenerateTextRequest.attributeTypeMap;
    }

    public constructor() {
    }
}



