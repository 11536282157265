/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApiRoundItemData } from '../models/ApiRoundItemData';
import { HttpFile } from '../http/http';

export class ApiRoundRecord {
    'timeBegan': Date;
    'timeFinished': Date;
    'timeElapsed': number;
    'roundGuid': string;
    'items': Array<ApiRoundItemData>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "timeBegan",
            "baseName": "TimeBegan",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "timeFinished",
            "baseName": "TimeFinished",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "timeElapsed",
            "baseName": "TimeElapsed",
            "type": "number",
            "format": "double"
        },
        {
            "name": "roundGuid",
            "baseName": "RoundGuid",
            "type": "string",
            "format": ""
        },
        {
            "name": "items",
            "baseName": "Items",
            "type": "Array<ApiRoundItemData>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ApiRoundRecord.attributeTypeMap;
    }

    public constructor() {
    }
}

