export const ApplicationName = 'App';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register',
  UserData: 'userdata'
};

const prefix = '/authentication';

export const Settings = {
  Authority : 'http://localhost:4000/oidc',
  RedirectUri: 'http://localhost:3000/authentication/login-callback',
  LogoutRedirectUri: 'http://localhost:3000/authentication/logout-callback'
}

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationClientConfigurationUrl: `http://localhost:4000/oidc/.well-known/openid-configuration`,
  ApiAuthorizationPrefix: prefix,
  Login: `${LoginActions.Login}`,
  LoginFailed: `${LoginActions.LoginFailed}`,
  LoginCallback: `${LoginActions.LoginCallback}`,
  Register: `${LoginActions.Register}`,
  UserData: `${LoginActions.UserData}`,
  Profile: `${LoginActions.Profile}`,
  LogOut: `${LogoutActions.Logout}`,
  LoggedOut: `${LogoutActions.LoggedOut}`,
  LogOutCallback: `${LogoutActions.LogoutCallback}`,
  IdentityRegisterPath: '/register',
  IdentityManagePath: '/Identity/Account/Manage',
  IdentityPersonalPath: '/Identity/Account/Manage/PersonalData',
  IdentityDownloadPath: '/Identity/Account/Manage/DownloadPersonalData'
};
