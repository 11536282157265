import React, { useState } from "react";
import { AppStyle, MainColumn, TileContainer } from "../App";
import { ContentTile } from "../Components/ModeTile";
import { ControllerModeContainer, ColumnLayout } from "../Styles/Styles";
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths, Settings } from './ApiAuthorizationConstants';

interface LoginProps {
    action: string
}

export const StyledLogin: React.FC<LoginProps> = (props) => {

    const [message, setMessage] = useState("")

    const login = async (returnUrl: string) => {
        const state = { returnUrl };
        authService.setAuthority(Settings.Authority)
        authService.setRedirectUri(Settings.RedirectUri)
        const result = await authService.signIn(state);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                break;
            case AuthenticationResultStatus.Success:
                await navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                // @ts-expect-error
                setMessage(result.message)
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }

    const processLoginCallback = async () => {
        const url = window.location.href;
        authService.setAuthority(Settings.Authority)
        authService.setRedirectUri(Settings.RedirectUri)

        const result = await authService.completeSignIn(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                // @ts-expect-error
                await navigateToReturnUrl(getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                // @ts-expect-error
                setMessage(result.message)
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }

    const getReturnUrl = (state?: any) => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    const redirectToRegister = () => {
        redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    const redirectToProfile = () => {
        redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    const navigateToReturnUrl = (returnUrl: string) => {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }

    const action = props.action;
    switch (action) {
        case LoginActions.Login:
            login(getReturnUrl());
            break;
        case LoginActions.LoginCallback:
            processLoginCallback();
            break;
        case LoginActions.LoginFailed:
            const params = new URLSearchParams(window.location.search);
            const error = params.get(QueryParameterNames.Message);
            setMessage(error!!)
            break;
        case LoginActions.Profile:
            redirectToProfile();
            break;
        case LoginActions.Register:
            redirectToRegister();
            break;
        default:
            throw new Error(`Invalid action '${action}'`);
    }

    const RenderMessage = (action: string) => {
        switch (action) {
            case LoginActions.Login:
                return ("Processing login");
            case LoginActions.LoginCallback:
                return ("Processing login callback");
            case LoginActions.Profile:
            case LoginActions.Register:
            case LoginActions.UserData:
                return ("");
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    return <AppStyle>
        <MainColumn>
            <TileContainer>
                <ContentTile>
                    <ControllerModeContainer>
                        <ColumnLayout>
                            {!!message && message}
                            {RenderMessage(action)}
                        </ColumnLayout>
                    </ControllerModeContainer>
                </ContentTile>
            </TileContainer>
        </MainColumn>
    </AppStyle>

}