export function ToLowerCaseKeys(o1: any) {

    var o2: any = {}

    let k: keyof typeof o1

    for (k in o1) {
        let j = `${k[0].toLowerCase()}${k.slice(1)}`
        const value: any = o1[k]

        if (Array.isArray(value)) {
            o2[j] = value.map(ToLowerCaseKeys)
        } else {
            o2[j] = value
        }
    }

    return o2
}