import { FFmpeg } from "@ffmpeg/ffmpeg"
import { toBlobURL } from "@ffmpeg/util"
import { ParentSize } from "@visx/responsive"
import React, { useContext, useEffect, useState } from "react"
import { LiveAudioVisualizer } from "react-audio-visualize"
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder"
import { TextStyle } from "../../App"
import { MicrophoneIcon } from "../../Icons/MicrophoneIcon"
import { ColumnLayout, RowLayout } from "../../Styles/Styles"
import { DisplayItemAboveFoldProps, DisplayItemBelowFoldProps, NewDisplayItemAboveFoldProps, NewDisplayItemBelowFoldProps } from "../../Types/DisplayItem"
import { LanguageUnitDefinition } from "../../Types/LanguageDefinition"
import { ZhLanguageUnitTypes } from "../../Types/Zh/LanguageDefinition"
import { FFMpegAccessor } from "../../Utility/FFmpegAccessor"
import { StyledButton } from "../ModeTile"
import { ApiContext } from "../../Contexts/Api/ApiContext"
import { UnwrapApiCall } from "../../Contexts/Api/DefaultApiContext"
import { CnReadings } from "../../data/cn-readings"
import styled from "styled-components"
import { SpeakerIcon } from "../../Icons/SpeakerIcon"
import { ResponseType } from "../../Contexts/Api/RootApiContext"
import { PlayTextAudio } from "../PlayTextAudio"
import Column from "antd/es/table/Column"

export type ZhBaseUnitType = { Id: string, LanguageUnitDefinition: LanguageUnitDefinition<ZhLanguageUnitTypes> }
interface Coordinate {
    x: number,
    y: number
}

const StyledInput = styled.input`
    height: 32px;
    font-size: 1rem;
    font-family: Consolas;
    border-radius: 16px;
    border: none;
    background-color: #F5EEE6;
    padding-left: 12px;
`

export const NewZhBaseUnitGlyphToMeaningAboveFold: React.FC<NewDisplayItemAboveFoldProps> = props => {

    const api = useContext(ApiContext)

    const [readingAnswer, setReading] = useState("")
    const [meaningAnswer, setMeaning] = useState("")

    const [showDebug, setShowDebug] = useState(false)

    const meaningInputRef = React.createRef<HTMLInputElement>()

    const [correct, setCorrect] = useState(false)

    const [evaluating, setEvaluating] = useState(false)

    const textSize = 10 / props.Data.item.length

    const Evaluate = () => {


        // const allReadings = CnReadings[props.Unit.Id]

        var anyReadingCorrect = false

        var readingAnswerCorrect = false


        // if (allReadings != undefined) {
        //     // if any reading is correct, pass the unit
        //     for (const aReading of allReadings) {
        //         const splitReadingAnswer = readingAnswer.split(" ")
        //         const splitReading = aReading?.split(" ")
        //         const splitUnit = props.Unit.Id.split("")


        //         if (splitReading != undefined) {
        //             if (splitReadingAnswer.length == splitReading.length) {
        //                 for (var i in splitReading) {
        //                     readingAnswerCorrect = (readingAnswerCorrect || (splitReading[i].toLowerCase() == splitReadingAnswer[i].trim().toLowerCase()))

        //                     if (readingAnswerCorrect) {
        //                         break;
        //                     }
        //                 }

        //             }
        //         } else {
        //             // fallback
        //             if (splitReadingAnswer.length == splitUnit.length) {
        //                 for (var i in splitUnit) {
        //                     const reading = CnReadings[splitUnit[i]][0] // pick first - definitely fail-prone, bad code
        //                     if (reading == null) {
        //                         // ????
        //                     } else {
        //                         readingAnswerCorrect = (reading == splitReadingAnswer[i].trim().toLowerCase())
        //                     }

        //                     if (readingAnswerCorrect) {
        //                         break;
        //                     }

        //                 }
        //             } else {
        //                 readingAnswerCorrect = false
        //             }
        //         }

        //         if (readingAnswerCorrect) {
        //             anyReadingCorrect = true;
        //             break;
        //         }
        //     }
        // } else {

        const reading = props.Data.properties["Reading"]

        if (reading.toLowerCase().trim() == readingAnswer.toLowerCase().trim()) {
            readingAnswerCorrect = true
            anyReadingCorrect = true
        } else {
            readingAnswerCorrect = false
        }


        // evaluate meaning answer
        const splitDef = props.Data.properties["PrimaryDefinition"].split(/,|;/).map((s: string) => s.toLowerCase().trim())
        console.log(splitDef)

        const meaningAnswerCorrect = splitDef.includes(meaningAnswer.trim().toLowerCase())

        if (anyReadingCorrect && meaningAnswerCorrect) {
            setCorrect(true)
            props.OnAnswer!!(true)
            setShowDebug(true)
        } else if (anyReadingCorrect && !meaningAnswerCorrect) {
            setEvaluating(true)
            api.EvaluateMeaning({ item: props.Data.item, answer: meaningAnswer }).then(UnwrapApiCall).then(result => {
                setEvaluating(false)
                if (result == 1) {
                    setCorrect(true)
                    props.OnAnswer!!(true)
                } else {
                    setCorrect(false)
                    props.OnAnswer!!(false)
                }
                setShowDebug(true)
            })
        } else {
            setCorrect(false)
            props.OnAnswer!!(false)
            setShowDebug(true)
        }


        meaningInputRef!!.current!!.blur()

    }

    return <>
        <TextStyle size={textSize} weight='bold'>{props.Data.item}</TextStyle>
        {showDebug && <TextStyle colour={correct ? "green" : "red"} style={{ backgroundColor: correct ? 'green' : 'red' }}>Debug: {props.Data.properties["Reading"]}</TextStyle>}

        {props.ShowAnswerInterface && <>
            <ColumnLayout>
                <StyledInput autoComplete="off" autoFocus placeholder="reading" type="text" onKeyDown={(e) => { if (e.key == "Enter") meaningInputRef.current?.focus() }} onChange={(e) => { setReading(e.target.value) }} />
                <StyledInput autoComplete="off" ref={meaningInputRef} placeholder="meaning" type="text" onKeyDown={(e) => { if (e.key == "Enter") Evaluate() }} onChange={(e) => { setMeaning(e.target.value) }} />
                <StyledButton disabled={evaluating} onClick={Evaluate}>Submit</StyledButton>
                {evaluating && <TextStyle>Evaluating...</TextStyle>}
            </ColumnLayout>
        </>}
    </>
}



export const NewZhBaseUnitGlyphToMeaningBelowFold: React.FC<NewDisplayItemBelowFoldProps> = props => {

    return <ColumnLayout>
        <TextStyle size={3} weight='bold'>{props.Data.properties["DisplayReading"]}</TextStyle>
        <PlayTextAudio AutoPlay={props.Revealed} Text={props.Data.item} />
        <TextStyle size={1.5} weight='bold'>{props.Data.properties["PrimaryDefinition"]}</TextStyle>
    </ColumnLayout>
}

export const NewZhBaseUnitMeaningToWrittenGlyphAboveFold: React.FC<NewDisplayItemAboveFoldProps> = props => {

    const units = props.Data.item.split("")

    const correct: { [key: number]: boolean } = {}
    units.map((u, i) => { correct[i] = false })

    const SetCorrect = () => {
        // correct[index] = true
        // console.log(units[index] + " correct")

        // if (units.every((u, i) => correct[i])) {
        //     props.OnAnswer!!(true)
        // }
        props.OnAnswer!!(true)
    }

    return <>
        <ColumnLayout>
            <TextStyle size={3} weight='bold'>{props.Data.properties["DisplayReading"]}</TextStyle>
            <TextStyle size={1.5} weight='bold'>{props.Data.properties["PrimaryDefinition"]}</TextStyle>

            <RowLayout>
                <WordWriter Word={props.Data.item} SetCorrect={SetCorrect} />
                {/* {units.map((u, i) => {
                    return <HanziGlyphWriter key={i} Unit={u} index={i} SetCorrect={SetCorrect} />
                })} */}
            </RowLayout>

            <StyledButton style={{}} onClick={() => props.OnAnswer!!(false)}>Skip</StyledButton>
        </ColumnLayout>
    </>

}

export const WordWriter: React.FC<{ Word: string, SetCorrect: () => any }> = props => {

    const canvasRef = React.createRef<HTMLCanvasElement>()

    const units = props.Word.split("")

    const correct: { [key: number]: boolean } = {}
    units.map((u, i) => { correct[i] = false })

    const [whichIndex, setWhichIndex] = useState(0)

    const SetCorrect = (index: number) => {
        correct[index] = true
        if (whichIndex < units.length - 1) {
            setWhichIndex(whichIndex + 1)
        } else {
            setWhichIndex(whichIndex + 1)
            props.SetCorrect()
        }
    }

    return <>
        <ColumnLayout>
            <TextStyle size={3} weight='bold'>... {props.Word.slice(0, whichIndex)} ...</TextStyle>
            <RowLayout>
                <HanziGlyphWriter Unit={props.Word[whichIndex]} index={whichIndex} SetCorrect={SetCorrect} />
            </RowLayout>
        </ColumnLayout>

    </>

}

export const HanziGlyphWriter: React.FC<{ Unit: string, index: number, SetCorrect: (index: number) => any }> = props => {
    const canvasRef = React.createRef<HTMLCanvasElement>()


    const [strokeList, setStrokeList] = useState<number[][][]>([])
    const [currentStroke, setCurrentStroke] = useState<number[][]>([])

    const [isDrawing, setIsDrawing] = useState(false)
    const [oldCoords, setOldCoords] = useState({ x: 0, y: 0 })
    const [oldMidCoords, setMidOldCoords] = useState({ x: 0, y: 0 })

    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [lookupWorker, setLookupWorker] = useState<Worker | undefined>(undefined)


    const [showDebug, setShowDebug] = useState(false)
    const [debugMatches, setDebugMatches] = useState([])

    const [wasmState, setWasmState] = useState("")

    const [error, setError] = useState<any>(undefined)


    const unit = props.Unit
    const setCorrect = props.SetCorrect

    useEffect(() => {

        function onWorkerMessage(e: any) {
            if (!e.data.what) return;
            setWasmState(e.data.what)
            if (e.data.what == "loaded") { setLoaded(true); setLoading(false); }
            else if (e.data.what == "lookup") {
                console.log(e.data.matches)
                setDebugMatches(e.data.matches)
                var matches = e.data.matches.map((e: any) => e.hanzi)
                if (matches.includes(unit)) {
                    setCorrect(props.index)
                }
            }
        }

        const worker = new Worker("/lib/hanzi_lookup/worker.js")
        worker.onmessage = onWorkerMessage;
        worker.onerror = (ev) => { setError(ev); console.log(ev) }
        // 
        // const worker = HanziLookupAccessor.worker
        worker.onmessage = onWorkerMessage
        worker.postMessage({ wasm_uri: '/lib/hanzi_lookup/hanzi_lookup_bg.wasm' });
        setLookupWorker(worker)
        setLoading(true)

        return () => {
            worker.terminate()
        }

    }, [unit, setCorrect])

    useEffect(() => {
        if (loaded == true) {
            clear()
        }
    }, [loaded])


    const inputCoords: (x: number, y: number) => { x: number, y: number } = (x: number, y: number) => {

        const canvas = canvasRef.current!!

        var rect = canvasRef.current!!.getBoundingClientRect()
        const height = rect.height
        const width = rect.width

        // var xCoord = x - canvas.offsetLeft
        // var yCoord = y - canvas.offsetTop
        // xCoord *= (width / rect.width)
        // yCoord *= (height / rect.height)

        // console.log({
        //     x: x - rect.left,
        //     y: y - rect.top
        // })

        // console.log({
        //     x: xCoord,
        //     y: yCoord
        // })

        return {
            x: x - rect.left,
            y: y - rect.top
        }
    }

    const midInputCoords: (x: number, y: number) => { x: number, y: number } = (x, y) => {
        return {
            x: oldCoords.x + (x >> 1),
            y: oldCoords.y + (y >> 1)
        }
    }

    const draw = (current: Coordinate, old: Coordinate, oldMid: Coordinate, currentMid: Coordinate) => {

        if (canvasRef.current == null) return;

        const canvasContext = canvasRef.current!!.getContext("2d")!!

        canvasContext.lineWidth = 5
        canvasContext.lineCap = "round"
        canvasContext.lineJoin = "round"

        canvasContext.beginPath()
        canvasContext?.moveTo(current.x, current.y)
        canvasContext?.quadraticCurveTo(oldCoords.x, oldCoords.y, oldCoords.x, oldCoords.y)
        canvasContext?.stroke()


    }

    const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
        const { clientX, clientY } = event;


        setIsDrawing(true)

        const coords = inputCoords(clientX, clientY)
        const _oldCoords = inputCoords(clientX, clientY)
        const _oldMidCoords = midInputCoords(clientX, clientY)
        const currentMidCoords = midInputCoords(coords.x, coords.y)

        currentStroke.push([coords.x, coords.y])


        draw(coords, oldCoords, oldMidCoords, currentMidCoords)

        setOldCoords(_oldCoords)
        setMidOldCoords(_oldMidCoords)
    };

    const handleTouchDown = (event: React.TouchEvent<HTMLElement>) => {

        const { clientX, clientY } = event.touches[0]

        console.log(event)
        setIsDrawing(true)


        const coords = inputCoords(clientX, clientY)
        // const _oldCoords = inputCoords(clientX, clientY)
        // const _oldMidCoords = midInputCoords(clientX, clientY)
        // const currentMidCoords = midInputCoords(coords.x, coords.y)

        // currentStroke.push([coords.x, coords.y])


        // draw(coords, coords, coords, coords)

        setOldCoords(coords)
        setMidOldCoords(coords)

    };

    const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
        const { clientX, clientY } = event;

        const coords = inputCoords(clientX, clientY)
        const currentMidCoords = midInputCoords(coords.x, coords.y)


        if (isDrawing) {
            draw(coords, oldCoords, oldMidCoords, currentMidCoords)

            setOldCoords(coords)
            setMidOldCoords(currentMidCoords)

            currentStroke.push([coords.x, coords.y])


        } else {
            setOldCoords(inputCoords(clientX, clientY))
        }
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLElement>) => {

        const { clientX, clientY } = event.touches[0]

        const coords = inputCoords(clientX, clientY)
        const currentMidCoords = midInputCoords(coords.x, coords.y)


        if (isDrawing) {
            draw(coords, oldCoords, oldMidCoords, currentMidCoords)

            setOldCoords(coords)
            setMidOldCoords(currentMidCoords)

            currentStroke.push([coords.x, coords.y])


        } else {
            setOldCoords(inputCoords(clientX, clientY))
        }

    };

    const handleMouseUp = (event: React.MouseEvent<HTMLElement>) => {
        const { clientX, clientY } = event;

        const coords = inputCoords(clientX, clientY)
        const currentMidCoords = midInputCoords(coords.x, coords.y)


        draw(coords, oldCoords, oldMidCoords, currentMidCoords)
        setIsDrawing(false)

        strokeList.push(currentStroke)
        setCurrentStroke([])
        try {

            // each stroke needs a minimum of 3 points for library to work
            // if only a single point is created, fake it
            if (strokeList[strokeList.length - 1].length == 1) {
                const modify = strokeList[strokeList.length - 1]
                modify.push(modify[0])
                modify.push(modify[0])
            }

            lookupWorker?.postMessage({ strokes: strokeList, limit: 8 })
        } catch (e: any) {
            alert(e)
        }

    };

    const handleTouchUp = (event: React.TouchEvent<HTMLElement>) => {

        // const { clientX, clientY } = event.touches[0]

        // const coords = inputCoords(clientX, clientY)
        // const currentMidCoords = midInputCoords(coords.x, coords.y)


        // draw(coords, oldCoords, oldMidCoords, currentMidCoords)
        setIsDrawing(false)

        console.log(currentStroke)
        if (currentStroke.length == 0) {
            return;
        }

        if (currentStroke.length == 1) {
            currentStroke.push(currentStroke[0])
            currentStroke.push(currentStroke[0])
        }


        strokeList.push(currentStroke)
        setCurrentStroke([])
        // each stroke needs a minimum of 3 points for library to work
        // if only a single point is created, fake it

        lookupWorker?.postMessage({ strokes: strokeList, limit: 8 })

    };


    const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
        const { clientX, clientY } = event;

        setOldCoords(inputCoords(clientX, clientY))
        setMidOldCoords(inputCoords(clientX, clientY))

    };

    const handleTouchOver = (event: React.TouchEvent<HTMLElement>) => {
        const { clientX, clientY } = event.touches[0]

        setOldCoords(inputCoords(clientX, clientY))
        setMidOldCoords(inputCoords(clientX, clientY))

    };

    const clear = () => {

        setDebugMatches([])
        setStrokeList([])
        setCurrentStroke([])
        setIsDrawing(false)
        const context = canvasRef.current?.getContext('2d')!!;
        context.clearRect(0, 0, context.canvas.width, context.canvas.height)

        context.setLineDash([1, 1]);
        context.lineWidth = 0.5;
        context.strokeStyle = "grey";
        context.beginPath();
        context.moveTo(0, 0);
        context.lineTo(context.canvas.width, 0);
        context.lineTo(context.canvas.width, context.canvas.height);
        context.lineTo(0, context.canvas.height);
        context.lineTo(0, 0);
        context.stroke();
        context.beginPath();
        context.moveTo(0, 0);
        context.lineTo(context.canvas.width, context.canvas.height);
        context.stroke();
        context.beginPath();
        context.moveTo(context.canvas.width, 0);
        context.lineTo(0, context.canvas.height);
        context.stroke();
        context.beginPath();
        context.moveTo(context.canvas.width / 2, 0);
        context.lineTo(context.canvas.width / 2, context.canvas.height);
        context.stroke();
        context.beginPath();
        context.moveTo(0, context.canvas.height / 2);
        context.lineTo(context.canvas.width, context.canvas.height / 2);
        context.stroke();
        context.strokeStyle = "black";


    }

    const undo = () => {
        const context = canvasRef.current?.getContext('2d')!!;

        strokeList.pop()
    }

    return <>
        <ColumnLayout>
            {loaded && <>
                <div>
                    <canvas

                        style={{ touchAction: 'none', border: '1px solid black' }}
                        ref={canvasRef}
                        onLoad={clear}
                        onTouchStart={handleTouchDown}
                        onTouchEnd={handleTouchUp}
                        onTouchMove={handleTouchMove}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseOver={handleMouseOver}
                        height={250} width={250} />
                </div>
                {showDebug && <>
                    <TextStyle>wasm state: {wasmState}</TextStyle>
                    {error != undefined && <TextStyle>{JSON.stringify(error, ["message", "arguments", "type", "name"])}</TextStyle>}
                    <RowLayout>
                        {debugMatches.map((h: any) => <TextStyle>{h.hanzi}</TextStyle>)}
                    </RowLayout>
                </>}

            </>}
            {!loaded && <><TextStyle>Loading...</TextStyle>
                <StyledButton onClick={() => { setLoading(false); setLoaded(false) }}>Reload</StyledButton>
            </>}
            <RowLayout style={{ justifyContent: 'center' }}>
                <StyledButton style={{ alignSelf: 'center', width: 120 }} onClick={() => setShowDebug(!showDebug)}>Debug toggle</StyledButton>
                <StyledButton style={{ alignSelf: 'center', width: 120 }} onClick={clear}>Clear</StyledButton>
            </RowLayout>
        </ColumnLayout>
    </>
}

export const NewZhBaseUnitMeaningToWrittenGlyphBelowFold: React.FC<NewDisplayItemBelowFoldProps> = props => {
    const textSize = 10 / props.Data.item.length
    return <>
        <ColumnLayout>
            <RowLayout>
                <TextStyle size={textSize} weight='bold'>{props.Data.item}</TextStyle>
            </RowLayout>
        </ColumnLayout>
    </>
}

export const NewZhBaseUnitGlyphToSpokenReadingAboveFold: React.FC<NewDisplayItemAboveFoldProps> = props => {

    const textSize = 10 / props.Data.item.length
    return <>
        <TextStyle size={textSize} weight='bold'>{props.Data.item}</TextStyle>

        {props.ShowAnswerInterface &&
            <>
                <ColumnLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <AudioRecorderInterface Unit={props.Data.item} OnAnswer={props.OnAnswer!!} />
                    </RowLayout>
                </ColumnLayout>
            </>
        }


        {/* <RowLayout>
            <StyledButton onClick={() => props.OnAnswer!!(true)}>Correct</StyledButton>
            <StyledButton onClick={() => props.OnAnswer!!(false)}>Incorrect</StyledButton>
        </RowLayout> */}
    </>
}

export const AudioRecorderInterface: React.FC<{ Unit: string, OnAnswer: (answer: boolean) => any }> = props => {

    const api = useContext(ApiContext)

    const [holdingButton, setHoldingButton] = useState(false)

    const [audioInputDeviceDetected, setAudioInputDeviceDetected] = useState(false)

    const [evaluating, setEvaluating] = useState(false)
    const [nullResult, setNullResult] = useState(false)

    const [answerIncorrect, setAnswerIncorrect] = useState(false)
    const [repeatCount, setRepeatCount] = useState(0)

    const [pronunciationScore, setPronunciationScore] = useState(0)
    const [pronunciationAssessment, setPronunciationAssessment] = useState<any>(undefined)

    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
        mediaRecorder
    } = useAudioRecorder({ noiseSuppression: true, channelCount: 1, echoCancellation: true }, (e: DOMException) => { console.log(e) });

    const process = () => {

    }

    useEffect(() => {

        navigator.mediaDevices.enumerateDevices().then((devices) => {

            const dD = devices.filter((d) => d.kind === 'audioinput');
            console.log(dD)
            if (dD.length > 0) {
                setAudioInputDeviceDetected(true)
            }
        })

        const addAudioElement = async (blob: Blob) => {


            if (!crossOriginIsolated) {
                console.warn(
                    `This website is not "cross-origin isolated". wav encoding requires cross origin isolation. Please visit https://web.dev/cross-origin-isolation-guide/ and https://web.dev/coop-coep/ for information on how to make your website "cross-origin isolated"`
                );
            } else {
                console.log(blob)

                const baseURL = '/lib/ffmpeg'
                const ffmpeg = FFMpegAccessor.ffmpeg
                ffmpeg.on('log', ({ message }) => {
                    // console.log(message);
                });
                // toBlobURL is used to bypass CORS issue, urls with the same
                // domain can be used directly.

                if (!ffmpeg.loaded)
                    await ffmpeg.load({
                        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
                        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
                    });

                const outputName = `output.wav`;

                await ffmpeg.writeFile('input.webm', new Uint8Array(await blob.arrayBuffer()));

                await ffmpeg.exec(['-i', 'input.webm', outputName]);
                const outputData = await ffmpeg.readFile(outputName);
                const outputBlob = new Blob([outputData], {
                    type: `audio/wav`,
                });

                const url = URL.createObjectURL(outputBlob);
                (blob as any).name = url;


                setEvaluating(true)
                // @ts-ignore
                const call = await api.EvaluatePronunciation(props.Unit, outputBlob)
                const result = await UnwrapApiCall(call)

                if (result == undefined) {
                    setEvaluating(false)
                    setNullResult(true)
                } else {
                    setEvaluating(false)

                    console.log(result)
                    const accuracyScore = result.NBest[0].PronunciationAssessment.PronScore

                    console.log(result.NBest[0].PronunciationAssessment)

                    setPronunciationScore(accuracyScore)
                    setPronunciationAssessment(result.NBest[0].PronunciationAssessment)

                    console.log(accuracyScore)

                    if (accuracyScore < 80) {
                        if (repeatCount < 2) {
                            setAnswerIncorrect(true)
                            setRepeatCount(repeatCount + 1)
                        } else {
                            props.OnAnswer(accuracyScore > 80)
                        }
                    } else {
                        props.OnAnswer(accuracyScore > 80)
                    }




                }
            }

        }

        if (!recordingBlob) return;

        addAudioElement(recordingBlob)

        // recordingBlob will be present at this point after 'stopRecording' has been called
    }, [recordingBlob])

    const mouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setHoldingButton(true)
        startRecording()
    }

    const mouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        setHoldingButton(false)
        stopRecording()
    }

    const startHold = (e: React.TouchEvent<HTMLDivElement>) => {
        setHoldingButton(true)
        startRecording()
    }

    const release = (e: React.TouchEvent<HTMLDivElement>) => {
        setHoldingButton(false)
        stopRecording()
    }

    useEffect(() => {

        const maxRecordingTime = 3 * props.Unit.length
        if (mediaRecorder != undefined && recordingTime > maxRecordingTime) {
            stopRecording()
            setHoldingButton(false)
        }
    }, [mediaRecorder, recordingTime, stopRecording])

    return <>
        {holdingButton && mediaRecorder != undefined && mediaRecorder!!.mimeType != "" && <>
            <div onTouchEnd={release} onMouseUp={mouseUp} style={{ backdropFilter: 'blur(3px)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000040' }}>

            </div>

            <div onMouseUp={mouseUp} style={{ position: 'fixed', zIndex: 100, top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '50%' }}>
                {mediaRecorder && (
                    <ParentSize>
                        {(parentSize) => {
                            return <LiveAudioVisualizer
                                mediaRecorder={mediaRecorder}
                                width={parentSize.width}
                                height={parentSize.height}
                                gap={3}
                                barWidth={3}
                            />
                        }}
                    </ParentSize>

                )}
            </div>
        </>}

        <ColumnLayout>
            {audioInputDeviceDetected && <div onContextMenu={() => { return false }} onMouseDown={mouseDown} onMouseUp={mouseUp} onTouchStart={startHold} onTouchEnd={release} style={{ userSelect: 'none', padding: 24 }}>
                <MicrophoneIcon />
            </div>}
            {/* {holdingButton && mediaRecorder != undefined && mediaRecorder!!.mimeType == "" && <div>Loading...</div>} */}
            {!audioInputDeviceDetected && <div>No microphone detected.</div>}
            {evaluating && <div>Evaluating...</div>}
            {pronunciationAssessment == undefined && nullResult && <div>No result. Try again.</div>}
            {pronunciationScore != 0 && <div>Score: {pronunciationScore}</div>}
            {pronunciationAssessment != undefined && <div>
                <TextStyle>Pronunciation: {pronunciationAssessment.PronScore}</TextStyle>
                <TextStyle>Accuracy: {pronunciationAssessment.AccuracyScore}</TextStyle>
                <TextStyle>Fluency: {pronunciationAssessment.FluencyScore}</TextStyle>
                <TextStyle>Completeness: {pronunciationAssessment.CompletenessScore}</TextStyle>
            </div>}
            {answerIncorrect && repeatCount < 3 && <div>Incorrect. Try again. Tries remaining: {3 - repeatCount}</div>}
        </ColumnLayout>
    </>
}

export const NewZhBaseUnitGlyphToSpokenMeaningBelowFold: React.FC<NewDisplayItemBelowFoldProps> = props => {

    return <>
        <ColumnLayout>
            <RowLayout>
                <TextStyle size={2} weight='bold'>{props.Data.properties['DisplayReading']}</TextStyle>
                <PlayTextAudio Text={props.Data.item} />
            </RowLayout>
        </ColumnLayout>
    </>
}