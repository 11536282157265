export enum ZhLanguageUnitTypes {
    Base,
    Extra,
    BaseFromTexts,
    Composite,
    FromSingleText,
    SentenceBreakdown
}

export const ZhLanguageUnitTypesText = {
    [ZhLanguageUnitTypes.Base]: "Base",
    [ZhLanguageUnitTypes.Extra]: "Extra",
    [ZhLanguageUnitTypes.BaseFromTexts]: "BaseFromTexts",
    [ZhLanguageUnitTypes.Composite]: "Composite",
    [ZhLanguageUnitTypes.FromSingleText]: "FromSingleText",
    [ZhLanguageUnitTypes.SentenceBreakdown]: "SentenceBreakdown",
}

export enum ZhLanguageItemTypes {
    Radical,
    Hanzi,
    Word,
    CoreSentence,
    SentenceFromText
}

export const ZhLanguageItemTypesText = {
    [ZhLanguageItemTypes.Radical]: "Radical",
    [ZhLanguageItemTypes.Hanzi]: "Hanzi",
    [ZhLanguageItemTypes.Word]: "Word",
    [ZhLanguageItemTypes.CoreSentence]: "CoreSentence",
    [ZhLanguageItemTypes.SentenceFromText]: "SentenceFromText",
}

export enum ZhLanguageTestTypes {
    GlyphToMeaning,
    MeaningReadingToWrittenGlyph,
    GlyphToSpokenReading,
    CompositeRead,
    CompositeDictation,
}

export const ZhLanguageTestypesText = {
    [ZhLanguageTestTypes.GlyphToMeaning]: "GlyphToMeaning",
    [ZhLanguageTestTypes.MeaningReadingToWrittenGlyph]: "MeaningReadingToWrittenGlyph",
    [ZhLanguageTestTypes.GlyphToSpokenReading]: "GlyphToSpokenReading",
    [ZhLanguageTestTypes.CompositeRead]: "CompositeRead",
    [ZhLanguageTestTypes.CompositeDictation]: "CompositeDictation",
}

export type ItemTypeTestConfigurationMap = {
    [itemType: number]: { PrimaryTestDefinitionId: number, TestDefinitions: number[] }
}