import { AppConfig } from "../../Types/AppConfig";
import { ActionRecord, ApiRoundRecord, BasicStatsResponse, DashboardResponse, DefaultApi, DictionaryQueryResult, EvaluateMeaningRequest, GenerateAudioResponse, GenerateTextRequest, HttpFile, Messages, PairedItem, ProcessTextRequest, ProcessTextResponse, ProcessedTextStructure, Query, ScoreCard, SegmentSentenceRequest, SelectUnitParameters, SelectedItems, SentencePageQueryResponse, SentenceResult, SentenceSegmentationResult, SentenceSelectionQuery, TextCategoryLink, TextEntry, TextProgressSummary, UnifiedLookupQueryResponse, UserGradeState } from "../../api";

export enum ResponseType {
    Success,
    Error
}

export interface ApiResponse<ResultType, StatusType> {
    object: ResultType
    status: StatusType
    responseType: ResponseType.Success
}

export interface ApiError<ErrorType, StatusType> {
    error: ErrorType
    status: StatusType
    responseType: ResponseType.Error
}

export enum ApiErrorType {
    Http403,
    Http500,
    HttpOther
}

export enum ApiRequestStatus {
    Success,
    Forbidden,
    Error
}

export type ApiCall<ResultType> = Promise<ApiEither<ResultType>>
export type ApiEither<ResultType> = ApiResponse<ResultType, ApiRequestStatus> | ApiError<ApiErrorType, ApiRequestStatus>


export interface RootApiContext {

    Settings?: AppConfig

    applySettings: (settings?: AppConfig) => void
    setApiKey: (key: string) => void

    CheckKey: (key: string) => ApiCall<boolean>

    AssistantChat: (messages: Messages) => ApiCall<string>

    Lookup: (query: Query) => ApiCall<DictionaryQueryResult>
    Translate: (query: Query) => ApiCall<string>
    Explain: (query: Query) => ApiCall<string>

    ProcessText: (request: ProcessTextRequest) => ApiCall<ProcessTextResponse>
    GenerateText: (request: GenerateTextRequest) => ApiCall<ProcessedTextStructure>
    SegmentSentences: (request: SegmentSentenceRequest) => ApiCall<SentenceSegmentationResult>

    EvaluateImage: (file: HttpFile) => ApiCall<SentenceSegmentationResult>
    EvaluatePronunciation: (id: string, blob: Blob) => ApiCall<any>

    GenerateAudio : (text: string) => ApiCall<GenerateAudioResponse>

    GradeState : () => ApiCall<UserGradeState[]>

    SelectItems: (parameters: SelectUnitParameters) => ApiCall<SelectedItems>

    FinishRound: (RoundRecord: ApiRoundRecord) => ApiCall<any>

    BasicStats: (StartDate: Date, EndDate: Date) => ApiCall<BasicStatsResponse>
    Dashboard: () => ApiCall<DashboardResponse>

    GetTexts: () => ApiCall<TextEntry[]>
    GetText: (ref: string) => ApiCall<ProcessedTextStructure>
    DeleteText: (ref: string) => ApiCall<void>

    SentencesWithWord: (word: string) => ApiCall<{ref: string, text: string, difficulty: number}[]>

    EvaluateTranslation: (reference: string, translation: string) => ApiCall<number>

    ReviewsDueToday: () => ApiCall<number>

    GradeText: (ref: string) => ApiCall<Record<string, number>>

    CalculateTextProgress: (ref: string) => ApiCall<TextProgressSummary>

    EvaluateMeaning: (request: EvaluateMeaningRequest) => ApiCall<number>

    LoadSentencePrimaryText: (ref: string) => ApiCall<string>

    ViewItemByGrade: (options: {unitId: number, itemType: number, grade: number}) => ApiCall<Record<string, PairedItem>>

    SentencePage: (query: {count: number, page: number}) => ApiCall<SentencePageQueryResponse>

    SentenceSelection: (query: SentenceSelectionQuery) => ApiCall<SentenceResult[]>

    GetTextCategoryLinks: () => ApiCall<TextCategoryLink[]>
    GetTextCategories: () => ApiCall<string[]>

    UnifiedLookup: (query: string) => ApiCall<UnifiedLookupQueryResponse>

    ScoreCard: () => ApiCall<ScoreCard>

    ProcessActionRecords: (records: ActionRecord[]) => ApiCall<boolean>
}