/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ActionRecordAction } from '../models/ActionRecordAction';
import { HttpFile } from '../http/http';

export class ActionRecord {
    'metadata': any | null;
    'timeElapsed': number;
    'timeFinished': Date;
    'timeBegan': Date;
    'count': number;
    'groupId': string;
    'ref': string;
    'action': ActionRecordAction;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "metadata",
            "baseName": "Metadata",
            "type": "any",
            "format": ""
        },
        {
            "name": "timeElapsed",
            "baseName": "TimeElapsed",
            "type": "number",
            "format": "double"
        },
        {
            "name": "timeFinished",
            "baseName": "TimeFinished",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "timeBegan",
            "baseName": "TimeBegan",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "count",
            "baseName": "Count",
            "type": "number",
            "format": "double"
        },
        {
            "name": "groupId",
            "baseName": "GroupId",
            "type": "string",
            "format": ""
        },
        {
            "name": "ref",
            "baseName": "Ref",
            "type": "string",
            "format": ""
        },
        {
            "name": "action",
            "baseName": "Action",
            "type": "ActionRecordAction",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ActionRecord.attributeTypeMap;
    }

    public constructor() {
    }
}



