import { useState } from "react"
import { TextLength, TextType, TextDifficulty, GeneratedTextStyle, TextPromptType, GenerateTextRequest } from "../api"
import { TextStyle } from "../App"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { StyledButton } from "../Components/ModeTile"

export const GenerateTextParametersView: React.FC<{ OnComplete: (options: any) => void }> = (props) => {

    enum TextDifficulty {
        Beginner,
        Intermediate,
        Advanced,
        Native
    }

    enum TextLength {
        Short,
        Medium,
        Long
    }

    enum TextType {
        Story,
        Analysis,
        Report
    }

    enum GeneratedTextStyle {
        Simple,
        Abstract,
        Poetry,
        Descriptive
    }

    enum TextPromptType {
        Prompt,
        Derive
    }

    const [textType, setTextType] = useState(TextType.Story)
    const [difficulty, setDifficulty] = useState(TextDifficulty.Beginner)
    const [textLength, setTextLength] = useState(TextLength.Short)
    const [generatedTextStyle, setGeneratedTextStyle] = useState(GeneratedTextStyle.Simple)
    const [dialoguePref, setDialoguePref] = useState(false)
    const [promptType, setPromptType] = useState(TextPromptType.Prompt)

    const [themePrompt, setThemePrompt] = useState<string>("")
    const [briefDescription, setBriefDescription] = useState<string>("")
    const [deriveFromPrompt, setDeriveFromPrompt] = useState<string>("")


    const UpdateThemePrompt = (prompt: string) => {
        setThemePrompt(prompt)
        setDeriveFromPrompt("")
    }

    const UpdateDeriveFromPrompt = (prompt: string) => {
        setDeriveFromPrompt(prompt)
        setThemePrompt("")
        setBriefDescription("")
    }

    const ApplySettings = () => {

        const generateTextRequest = new GenerateTextRequest()

        generateTextRequest.length = textLength as number
        generateTextRequest.type = textType as number
        generateTextRequest.difficulty = difficulty as number
        generateTextRequest.style = generatedTextStyle as number
        generateTextRequest.focusDialogue = dialoguePref
        generateTextRequest.promptType = promptType as number
        generateTextRequest.themePrompt = themePrompt
        generateTextRequest.briefDescription = briefDescription
        generateTextRequest.deriveFrom = deriveFromPrompt

        props.OnComplete(generateTextRequest)

    }

    return <>

        <ColumnLayout>
            <TextStyle size={2}>Settings</TextStyle>
            {/* <RowLayout>
                <TextStyle>Title</TextStyle>
                <input type="text" onChange={e => setTitle(e.target.value)} />
            </RowLayout> */}
            <RowLayout>
                <TextStyle>Length</TextStyle>
                <select defaultValue={TextLength.Short} onChange={(e) => { setTextLength(parseInt(e.target.value)) }}>
                    <option value={TextLength.Short}>Short</option>
                    <option value={TextLength.Medium}>Medium</option>
                    <option value={TextLength.Long}>Long</option>
                </select>
            </RowLayout>

            <RowLayout>
                <TextStyle>Type</TextStyle>
                <select defaultValue={TextType.Story} onChange={(e) => { setTextType(parseInt(e.target.value)) }}>
                    <option value={TextType.Story}>Story</option>
                    <option value={TextType.Analysis}>Analysis</option>
                    <option value={TextType.Report}>Report</option>
                </select>
            </RowLayout>

            <RowLayout>
                <TextStyle>Difficulty</TextStyle>
                <select defaultValue={TextDifficulty.Beginner} onChange={(e) => { setDifficulty(parseInt(e.target.value)) }}>
                    <option value={TextDifficulty.Beginner}>Beginner</option>
                    <option value={TextDifficulty.Intermediate}>Intermediate</option>
                    <option value={TextDifficulty.Advanced}>Advanced</option>
                    <option value={TextDifficulty.Native}>Native</option>
                </select>
            </RowLayout>

            <RowLayout>
                <TextStyle>Style</TextStyle>
                <select defaultValue={GeneratedTextStyle.Simple} onChange={(e) => { setGeneratedTextStyle(parseInt(e.target.value)) }}>
                    <option value={GeneratedTextStyle.Simple}>Simple</option>
                    <option value={GeneratedTextStyle.Descriptive}>Descriptive</option>
                    <option value={GeneratedTextStyle.Abstract}>Abstract</option>
                    <option value={GeneratedTextStyle.Poetry}>Poetry</option>
                </select>
            </RowLayout>

            <RowLayout>
                <TextStyle>Focus dialogue:</TextStyle>
                <input type="checkbox" onChange={(e) => setDialoguePref(e.target.checked)} />
            </RowLayout>

            <RowLayout>
                <TextStyle>Prompt type:</TextStyle>
                <select defaultValue={GeneratedTextStyle.Simple} onChange={(e) => { setPromptType(parseInt(e.target.value)) }}>
                    <option value={TextPromptType.Prompt}>User defined</option>
                    <option value={TextPromptType.Derive}>Derive from work</option>
                </select>
            </RowLayout>

            {promptType == TextPromptType.Prompt && <>
                <RowLayout>
                    <TextStyle>Theme prompt:</TextStyle>
                    <textarea value={themePrompt} onChange={(e) => UpdateThemePrompt(e.target.value)} />
                </RowLayout>
                <RowLayout>
                    <TextStyle>Brief description:</TextStyle>
                    <textarea onChange={(e) => setBriefDescription(e.target.value)} />
                </RowLayout>
            </>}

            {promptType == TextPromptType.Derive && <>
                <RowLayout>
                    <TextStyle>Derive from:</TextStyle>
                    <input value={deriveFromPrompt} onChange={(e) => UpdateDeriveFromPrompt(e.target.value)} type="text" />
                </RowLayout>
            </>}

            <RowLayout>
                <StyledButton onClick={ApplySettings}>Generate</StyledButton>
            </RowLayout>

        </ColumnLayout>
    </>
}   