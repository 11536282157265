import { ApiKeyAuthentication, IsomorphicFetchHttpLibrary, DefaultApi } from "../api";
import { ServerConfiguration } from "../api/servers";
import { ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration";
import { ExecuteApi } from "../Contexts/Api/DefaultApiContext";
import { ResponseType } from "../Contexts/Api/RootApiContext";
import { db } from "../Database/db";
import { ItemHistoryElement, LanguageFundamentalType } from "../Database/HistoryState";
import { ZhLanguageUnitTypes } from "../Types/Zh/LanguageDefinition";

export async function SimpleItemCache() {
    const itemHistory = await db.ItemHistory.toArray()

    const itemHistoryCache: { [key: string]: ItemHistoryElement } = {};
    itemHistory.filter(uh => uh.TestDefinitionId == 0).map(uh => itemHistoryCache[uh.UnitKey] = uh)
    /*
        const conf = {
            baseServer: new ServerConfiguration("http://localhost:4000", {}),
            authMethods: { "api_key": new ApiKeyAuthentication("hhCcjK5Rc1splX5m8z42") },
            middleware: [],
            httpApi: new IsomorphicFetchHttpLibrary()
        }
    
        const Api = new DefaultApi(conf)
    
        return await ExecuteApi(() => { return Api.getUnits() }).then(resp => {
            if (resp.responseType == ResponseType.Success) {
                return resp.object.units
            } else {
    
            }
        }).then((units) => {
    
            Promise.all(Object.keys(units).map((u => {
                const ldu = units[u]
    
                if (itemHistoryCache[u] == undefined) {
                    console.log(`unit ${u} not found. creating new item history`)
                    for (var test of Object.keys(ZhLanguageConfiguration.BaseUnitTestDefinitions)) {
    
                        const newItemHistory = {
                            TestDefinitionId: ZhLanguageConfiguration.BaseUnitTestDefinitions[parseInt(test)].id,
                            UnitKey: u,
                            FundamentalType: LanguageFundamentalType.Base,
                            UnitTypes: [ZhLanguageUnitTypes.Word],
                            Introduced: false,
                            Unlocked: false,
                            LastSeen: undefined,
                            Due: undefined,
                            Stability: 0,
                            Difficulty: 0,
                            Reps: 0,
                            Lapses: 0,
                            LearningState: 0,
                            Score: 0,
                            Record: []
                        }
    
                        if (parseInt(test) == ZhLanguageConfiguration.PrimaryTestDefinitionId) {
                            itemHistoryCache[u] = newItemHistory
                        }
                        
                        db.ItemHistory.add(newItemHistory)
                    }
    
    
                } else {
    
                }
            })))
    
    
    
        }).then(() => {
            return itemHistoryCache
        })
    */

    return itemHistoryCache
}
