import { EmptyBelowFold } from "../../../../Components/TestFolds/Empty"
import { NewZhBaseUnitGlyphToMeaningAboveFold, NewZhBaseUnitGlyphToMeaningBelowFold, NewZhBaseUnitGlyphToSpokenMeaningBelowFold, NewZhBaseUnitGlyphToSpokenReadingAboveFold, NewZhBaseUnitMeaningToWrittenGlyphAboveFold, NewZhBaseUnitMeaningToWrittenGlyphBelowFold } from "../../../../Components/TestFolds/NewZhBaseUnit"
import { NewZhCompositeUnitSentenceBaseAboveFold, NewZhCompositeUnitSentenceBaseBelowFold, ZhCompositeDictationTestAboveFold } from "../../../../Components/TestFolds/NewZhCompositeUnit"
import { ZhBaseUnitGlyphToMeaningAboveFold, ZhBaseUnitGlyphToMeaningBelowFold, ZhBaseUnitMeaningToWrittenGlyphAboveFold, ZhBaseUnitGlyphToSpokenReadingAboveFold } from "../../../../Components/TestFolds/ZhBaseUnit"
import { ZhCompositeUnitSentenceBaseAboveFold, ZhCompositeUnitSentenceBaseBelowFold } from "../../../../Components/TestFolds/ZhCompositeUnit"
import { NewDisplayItemAboveFoldProps, NewDisplayItemBelowFoldProps } from "../../../../Types/DisplayItem"
import { ZhBaseUnitTestDefinition } from "../../../../Types/Zh/LanguageDefinition"
import { NewRoundItemData } from "../../../../Views/NewRoundView"
import { ZhLanguageTestTypes, ZhLanguageUnitTypes } from "../Types"

export interface TestDefinition {
    id: number

    Label: string
    Reintroduce: boolean

    AboveFold: React.FC<NewDisplayItemAboveFoldProps>
    BelowFold: React.FC<NewDisplayItemBelowFoldProps>

    UserIndicatesIfCorrect: (roundItem: NewRoundItemData) => boolean

}
export type UnitTestInterfaceConfiguration = Record<number, Record<number, TestDefinition>>

export const ZhUnitTestInterfaceConfig: UnitTestInterfaceConfiguration = {

    [ZhLanguageUnitTypes.Base]: {

        [ZhLanguageTestTypes.GlyphToMeaning]: {
            id: ZhLanguageTestTypes.GlyphToMeaning,

            Label: 'GlyphToMeaning',
            Reintroduce: false,

            AboveFold: NewZhBaseUnitGlyphToMeaningAboveFold,
            BelowFold: NewZhBaseUnitGlyphToMeaningBelowFold,

            UserIndicatesIfCorrect: (roundItem: NewRoundItemData) => {
                if (roundItem.LearningState == 2) {
                    return true;
                }
                return false;
            }

        },
        [ZhLanguageTestTypes.MeaningReadingToWrittenGlyph]: {
            id: ZhLanguageTestTypes.MeaningReadingToWrittenGlyph,

            Label: 'MeaningToWrittenGlyph',
            Reintroduce: false,

            AboveFold: NewZhBaseUnitMeaningToWrittenGlyphAboveFold,
            BelowFold: NewZhBaseUnitMeaningToWrittenGlyphBelowFold,

            UserIndicatesIfCorrect: (roundItem: NewRoundItemData) => false

        },
        [ZhLanguageTestTypes.GlyphToSpokenReading]: {
            id: ZhLanguageTestTypes.GlyphToSpokenReading,

            Label: 'GlyphToSpokenReading',
            Reintroduce: false,

            AboveFold: NewZhBaseUnitGlyphToSpokenReadingAboveFold,
            BelowFold: NewZhBaseUnitGlyphToSpokenMeaningBelowFold,

            UserIndicatesIfCorrect: (roundItem: NewRoundItemData) => false

        }
    },
    [ZhLanguageUnitTypes.Extra]: {

        [ZhLanguageTestTypes.GlyphToMeaning]: {
            id: ZhLanguageTestTypes.GlyphToMeaning,

            Label: 'GlyphToMeaning',
            Reintroduce: false,

            AboveFold: NewZhBaseUnitGlyphToMeaningAboveFold,
            BelowFold: NewZhBaseUnitGlyphToMeaningBelowFold,

            UserIndicatesIfCorrect: (roundItem: NewRoundItemData) => false

        },
        [ZhLanguageTestTypes.MeaningReadingToWrittenGlyph]: {
            id: ZhLanguageTestTypes.MeaningReadingToWrittenGlyph,

            Label: 'MeaningToWrittenGlyph',
            Reintroduce: false,

            AboveFold: NewZhBaseUnitMeaningToWrittenGlyphAboveFold,
            BelowFold: NewZhBaseUnitMeaningToWrittenGlyphBelowFold,

            UserIndicatesIfCorrect: (roundItem: NewRoundItemData) => false

        },
        [ZhLanguageTestTypes.GlyphToSpokenReading]: {
            id: ZhLanguageTestTypes.GlyphToSpokenReading,

            Label: 'GlyphToSpokenReading',
            Reintroduce: false,

            AboveFold: NewZhBaseUnitGlyphToSpokenReadingAboveFold,
            BelowFold: NewZhBaseUnitGlyphToSpokenMeaningBelowFold,

            UserIndicatesIfCorrect: (roundItem: NewRoundItemData) => false

        }
    },
    [ZhLanguageUnitTypes.Composite]: {
        [ZhLanguageTestTypes.CompositeRead]: {
            id: ZhLanguageTestTypes.CompositeRead,

            Label: "SentenceBase",

            AboveFold: NewZhCompositeUnitSentenceBaseAboveFold,
            BelowFold: NewZhCompositeUnitSentenceBaseBelowFold,

            Reintroduce: false,
            UserIndicatesIfCorrect: (roundItem: NewRoundItemData) => false,
        },
        [ZhLanguageTestTypes.CompositeDictation]: {
            id: ZhLanguageTestTypes.CompositeDictation,

            Label: "CompositeDictation",

            AboveFold: ZhCompositeDictationTestAboveFold,
            BelowFold: EmptyBelowFold,

            Reintroduce: false,
            UserIndicatesIfCorrect: (roundItem: NewRoundItemData) => false,
        }

    }

}