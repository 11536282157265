/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class GetSentencesWithWord200ResponseInner {
    'difficulty': number;
    'text': string;
    'ref': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "difficulty",
            "baseName": "difficulty",
            "type": "number",
            "format": "double"
        },
        {
            "name": "text",
            "baseName": "text",
            "type": "string",
            "format": ""
        },
        {
            "name": "ref",
            "baseName": "ref",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GetSentencesWithWord200ResponseInner.attributeTypeMap;
    }

    public constructor() {
    }
}

