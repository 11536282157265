import { useEffect, useState } from "react"
import { AppCallbacks, TextStyle } from "../App"
import { db } from "../Database/db"
import { ProcessedText } from "../Types/ProcessedText"
import { ChatView } from "./ChatView"

export const ChatHomeView: React.FC<AppCallbacks> = props => {

    enum ViewState {
        Main,
        Chat
    }


    const [loaded, setLoaded] = useState(false)
    const [menuSet, setMenuSet] = useState(false)
    const [viewState, setViewState] = useState(ViewState.Main)

    const [texts, setTexts] = useState<ProcessedText[]>([])

    const { SetMenu, OnExit } = props;

    useEffect(() => {

        const ChatMenuConfig = [
            // {
            //     Label: "Start Chat",
            //     OnClick: () => { setViewState(ViewState.Chat) }
            // },
            {
                Label: "Exit",
                OnClick: () => { OnExit() }
            }
        ]
        if (loaded && !menuSet) {
            SetMenu(ChatMenuConfig)
            setMenuSet(true)
        }

        if (!loaded) {
            setLoaded(true)
            // db.Texts.toArray().then((texts) => { setTexts(texts); setLoaded(true) })
        }
    }, [menuSet, ViewState.Chat, OnExit, SetMenu, loaded])

    return <>
        <ChatView />
    </>
}