/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DictionaryEntry } from '../models/DictionaryEntry';
import { HttpFile } from '../http/http';

export class DictionaryQueryResult {
    'language': string;
    'query': string;
    'results'?: Array<DictionaryEntry>;
    'related'?: Array<DictionaryEntry>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "language",
            "baseName": "Language",
            "type": "string",
            "format": ""
        },
        {
            "name": "query",
            "baseName": "Query",
            "type": "string",
            "format": ""
        },
        {
            "name": "results",
            "baseName": "Results",
            "type": "Array<DictionaryEntry>",
            "format": ""
        },
        {
            "name": "related",
            "baseName": "Related",
            "type": "Array<DictionaryEntry>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return DictionaryQueryResult.attributeTypeMap;
    }

    public constructor() {
    }
}

