/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ProcessedChapter } from '../models/ProcessedChapter';
import { HttpFile } from '../http/http';

export class ProcessedTextStructure {
    'label'?: string;
    'ref'?: string;
    'fullText'?: string;
    'chapters': Array<ProcessedChapter>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "label",
            "baseName": "Label",
            "type": "string",
            "format": ""
        },
        {
            "name": "ref",
            "baseName": "Ref",
            "type": "string",
            "format": ""
        },
        {
            "name": "fullText",
            "baseName": "FullText",
            "type": "string",
            "format": ""
        },
        {
            "name": "chapters",
            "baseName": "Chapters",
            "type": "Array<ProcessedChapter>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ProcessedTextStructure.attributeTypeMap;
    }

    public constructor() {
    }
}

