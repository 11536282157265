import './wdyr'; // <--- first import

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'animate.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './api-authorization/ApiAuthorizationRoutes';
import { RegistrationView } from './Views/RegistrationView';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

setUseWhatChange(process.env.NODE_ENV === 'development');

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: '/register',
    element: <RegistrationView />
  },
  {
    path: '/logged-out',
    element: <div>Logged out</div>
  },
  {
    path: `${ApplicationPaths.ApiAuthorizationPrefix}/*`,
    children: [
      { index: true },

      { path: "*", element: <ApiAuthorizationRoutes /> },
    ],
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
