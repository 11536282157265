/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ProgressStats } from '../models/ProgressStats';
import { HttpFile } from '../http/http';

export class TextProgressSummary {
    'words': ProgressStats;
    'characters': ProgressStats;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "words",
            "baseName": "words",
            "type": "ProgressStats",
            "format": ""
        },
        {
            "name": "characters",
            "baseName": "characters",
            "type": "ProgressStats",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TextProgressSummary.attributeTypeMap;
    }

    public constructor() {
    }
}

