import { TextStyle } from "../../App"
import { DisplayItemAboveFoldProps, DisplayItemBelowFoldProps } from "../../Types/DisplayItem"

export type ZhCompositeUnitType = { Id: string, Target: string, Primary: string }

export const ZhCompositeUnitSentenceBaseAboveFold: React.FC<DisplayItemAboveFoldProps<ZhCompositeUnitType>> = props => {

    return <>
        <TextStyle size={2} weight='bold'>{props.Unit.Target}</TextStyle>
    </>
}

export const ZhCompositeUnitSentenceBaseBelowFold: React.FC<DisplayItemBelowFoldProps<ZhCompositeUnitType>> = props => {

    return <>
        <TextStyle size={1} weight='bold'>{props.Unit.Primary}</TextStyle>
    </>
}