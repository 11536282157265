import { useState } from "react"
import styled from "styled-components"
import { TextStyle } from "../App"
import { TestDefinition, TestDefinitionMapType, UnitDefinitionMap, UnitDefinitionMapType } from "../Config/UnitDefinitionMap"
import { Data } from "../Types/Interfaces"
import { StyledButton } from "./ModeTile"
import { RoundItemData } from "../Views/RoundView"
import { LanguageDefinition, LanguageUnitDefinition } from "../Types/LanguageDefinition"
import { LanguageConfiguration, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { ZhBaseUnitTestDefinition } from "../Types/Zh/LanguageDefinition"
import { ZhBaseUnitType } from "./TestFolds/ZhBaseUnit"
import { ExtraUnitsDefinitionType } from "../Loaders/LanguageDefinitionLoader"
import { TextRefMap } from "../Database/TextStore"
import { LanguageFundamentalType } from "../Database/HistoryState"
import { ZhCompositeUnitType } from "./TestFolds/ZhCompositeUnit"

interface DisplayItemProps<Unit> {

    LanguageConfiguration: LanguageConfiguration<any>
    ExtraUnitsDefinition: ExtraUnitsDefinitionType
    TextRefMap: TextRefMap
    Introduce: boolean,
    RoundItem: RoundItemData,

    TimeLimit: number,

    OnIntroduced: () => any
    OnAnswer: (correct: boolean, timeElapsed: number) => any

}

const DisplayItemContentSpace = styled.div`
flex-basis: 40%;
justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 12px;

`

function SelectLanguageDefinitionUnit(key: string, base: LanguageDefinition<any>, extra: LanguageDefinition<any>) {
    if (base[key]) {
        return base[key]
    }

    if (extra[key])
        return extra[key]
}

export const DisplayItem: React.FC<Data<DisplayItemProps<any>>> = props => {
    const [revealFold, setRevealFold] = useState(false)

    const [answerSet, setAnswerSet] = useState(false)

    const onAnswer = (correct: boolean) => {
        setRevealFold(false)
        props.Data.OnAnswer(correct, timeElapsed)
        setTimeElapsed(0)
        setStartTime(new Date())
        setTimer(conditionallySetTimer)
    }

    const ItemDetails: {
        TestDefinition: TestDefinition<any> | undefined
        LanguageUnitDefinition: LanguageUnitDefinition<any> | undefined
        Content: ZhBaseUnitType | ZhCompositeUnitType | undefined
    } = {
        TestDefinition: undefined,
        LanguageUnitDefinition: undefined,
        Content: undefined
    }

    if (props.Data.RoundItem.ItemFundamentalType == LanguageFundamentalType.Base) {

        ItemDetails.TestDefinition = props.Data.LanguageConfiguration.BaseUnitTestDefinitions[props.Data.RoundItem.TestDefinition]
        ItemDetails.LanguageUnitDefinition = SelectLanguageDefinitionUnit(props.Data.RoundItem.UnitItemKey, props.Data.LanguageConfiguration.Definition, props.Data.ExtraUnitsDefinition)

        if (ItemDetails.LanguageUnitDefinition == undefined) {
            throw new Error(`language definition unit key ${props.Data.RoundItem.UnitItemKey} not found`)
        }
    
        ItemDetails.Content = {
            Id: props.Data.RoundItem.UnitItemKey, LanguageUnitDefinition: ItemDetails.LanguageUnitDefinition!!
        }

    } else if (props.Data.RoundItem.ItemFundamentalType == LanguageFundamentalType.Composite) {

        ItemDetails.TestDefinition = props.Data.LanguageConfiguration.CompositeUnitTestDefinitions[props.Data.RoundItem.TestDefinition]

        const textLookup = props.Data.TextRefMap[props.Data.RoundItem.UnitItemKey]

        ItemDetails.Content = {
            Id: props.Data.RoundItem.UnitItemKey, Primary: textLookup.Primary ? textLookup.Primary : "", Target: textLookup.Target
        }

    } else {
        throw new Error("unknown item type found in displayitem")
    }

    const [startTime, setStartTime] = useState(new Date())
    const [timeElapsed, setTimeElapsed] = useState(0)

    const conditionallySetTimer = () => {
        if (!revealFold && !props.Data.Introduce && props.Data.TimeLimit > 0) {
            return setTimeout(() => { console.log("time limit reached"); onResult(); }, props.Data.TimeLimit * 1000)
        } else {
            return undefined
        }
    }

    const [timer, setTimer] = useState<any>(conditionallySetTimer())

    const onResult = () => {
        clearTimeout(timer)

        var endTime = new Date()

        setTimeElapsed(endTime.getTime() - startTime.getTime())
        setRevealFold(true)
    }

    const [receivedAnswer, setReceivedAnswer] = useState<boolean | undefined>(undefined)

    const ReceiveAnswer = (answer: boolean) => {
        setRevealFold(true)
        setReceivedAnswer(answer)
        onResult()
    }



    return (
        <>
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>

                <DisplayItemContentSpace>
                    {ItemDetails.TestDefinition!!.AboveFold({ Unit: ItemDetails.Content, OnAnswer: ReceiveAnswer, ShowAnswerInterface: !props.Data.Introduce && !revealFold })}
                </DisplayItemContentSpace>
                <hr />
                <DisplayItemContentSpace>
                    {props.Data.Introduce && <>
                        {ItemDetails.TestDefinition!!.BelowFold({ Unit: ItemDetails.Content })}

                    </>}
                    {!props.Data.Introduce && <>
                        {revealFold && <>

                            {ItemDetails.TestDefinition!!.BelowFold({ Unit: ItemDetails.Content })}


                        </>}
                    </>}


                </DisplayItemContentSpace>
                <div style={{ marginBottom: '0', marginTop: 'auto' }}>
                    {props.Data.Introduce && <div style={{ padding: 24 }}>
                        <StyledButton onClick={props.Data.OnIntroduced}><TextStyle case='uppercase' size={2} weight='bold'>Next</TextStyle></StyledButton >
                    </div>}
                    {!revealFold && !props.Data.Introduce && ItemDetails.TestDefinition!!.UserIndicatesIfCorrect && <div style={{ padding: 24 }}>
                        <StyledButton style={{ width: '100%' }} onClick={onResult}><TextStyle case='uppercase' size={2} weight='bold'>Reveal</TextStyle></StyledButton>
                    </div>}
                    {revealFold && !props.Data.Introduce && <div style={{ marginBottom: 0, marginTop: 'auto' }}>
                        <TextStyle>Time elasped: {timeElapsed}ms</TextStyle>
                        {!ItemDetails.TestDefinition!!.UserIndicatesIfCorrect && <div style={{ padding: 12, gap: 12, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            {receivedAnswer == true && <TextStyle>✅ Correct ✅</TextStyle>}
                            {receivedAnswer == false && <TextStyle>❌ Incorrect ❌</TextStyle>}
                        </div>}
                        {receivedAnswer == false && <StyledButton onClick={() => onAnswer(true)}><TextStyle>Override Correct</TextStyle></StyledButton>}
                        {ItemDetails.TestDefinition!!.UserIndicatesIfCorrect && <div style={{ padding: 12, gap: 12, display: 'flex' }}>
                            <StyledButton onClick={() => onAnswer(false)}><TextStyle case='uppercase' size={1} weight='bold'>❌ Incorrect</TextStyle></StyledButton >
                            <StyledButton onClick={() => onAnswer(true)}><TextStyle case='uppercase' size={1} weight='bold'>✅ Correct</TextStyle></StyledButton >

                        </div>}
                        {!ItemDetails.TestDefinition!!.UserIndicatesIfCorrect && <>
                            <StyledButton onClick={() => onAnswer(receivedAnswer!!)}><TextStyle case='uppercase' size={1} weight='bold'>Continue</TextStyle></StyledButton >

                        </>}
                        <div style={{ padding: 12 }}>

                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}