/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export enum SentenceSelectionMethod {
    Recent = 'recent',
    NewTodayKnown = 'new-today-known',
    Lapsed = 'lapsed',
    Known = 'known',
    Nplusone = 'nplusone'
}
