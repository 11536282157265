import { useState } from "react"
import styled from "styled-components"
import { TextStyle } from "../App"
import { StyledButton } from "./ModeTile"

const PaginatorContainer = styled.div`
    width : 100%;
    height: 100%;
`

const PaginatorContent = styled.div`
    overflow: visible;
    height: calc(100%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    align-items: center;
`

const PaginatorControls = styled.div`
    display: flex;

`

export const Paginator: React.FC<{ children: React.ReactNode }> = props => {

    var elements = Object.assign([], props.children) as React.ReactElement[]

    console.log(elements.length)

    const pageSize = 200
    const maxPage = Math.floor(elements.length / pageSize) + 1

    const [page, setPage] = useState(0)

    const TurnPage = (n: number) => {
        if (n == -1) {
            if (page > 0) {
                setPage(page + n)
            }
        }

        if (n == 1) {
            if (page < maxPage - 1) {
                setPage(page + n)
            }
        }
    }

    const pages = []

    for (var i = 0; i < maxPage; i++) {
        var newPage = []
        for (var j = 0; j < pageSize; j++) {
            if (elements.length > 0) {
                newPage.push(elements.shift())
            }
        }
        pages.push(newPage)
    }

    return <>
        <PaginatorContainer>
            <PaginatorContent>
                {pages[page]}
            </PaginatorContent>
            <PaginatorControls>
                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginLeft: 0, marginRight: 'auto' }}>
                    <TextStyle>{page + 1}/{maxPage}</TextStyle>
                </div>
                <div style={{ marginLeft: 'auto', marginRight: 0 }}>
                    {page > 0 && <StyledButton onClick={() => TurnPage(-1)}>Prev</StyledButton>}
                    {page < maxPage - 1  && <StyledButton onClick={() => TurnPage(1)}>Next</StyledButton>}
                </div>
            </PaginatorControls>
        </PaginatorContainer>
    </>
}