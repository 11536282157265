import { useState } from "react";
import { ApplicationPaths, LogoutActions, QueryParameterNames, Settings } from "./ApiAuthorizationConstants";
import authService, { AuthenticationResultStatus } from "./AuthorizeService";
import { AppStyle, MainColumn, TileContainer } from "../App";
import { ContentTile } from "../Components/ModeTile";
import { ControllerModeContainer, ColumnLayout } from "../Styles/Styles";
import React from "react";

interface LogoutProps {
    action: string
}

export class ErrorBoundary extends React.Component<{ children: any }, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {    // Update state so the next render will show the fallback UI.    return { hasError: true };  }
    }
    componentDidCatch(error: any, errorInfo: any) {    // You can also log the error to an error reporting service    logErrorToMyService(error, errorInfo);  }
    }
    render() {
        if (this.state.hasError) { return <h1>Something went wrong.</h1>; }
        return this.props.children;
    }
}

export const StyledLogout: React.FC<LogoutProps> = props => {

    const [message, setMessage] = useState("")
    const [isReady, setIsReady] = useState(false)
    const [authenticated, setAuthenticated] = useState(false)

    const logout = async (returnUrl: string) => {

        authService.setAuthority(Settings.Authority)
        authService.setRedirectUri(Settings.LogoutRedirectUri)

        const state = { "post_logout_redirect_uri" :  Settings.LogoutRedirectUri};
        // const isauthenticated = await authService.isAuthenticated();
        // if (isauthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    // @ts-expect-error
                    setMessage(result.message)
                    break;
                default:
                    throw new Error("Invalid authentication result status.");
            }
        // } else {
        //     setMessage("Successfully logged out.")
        //     // this.setState({ message: "You successfully logged out!" });
        // }
    }

    const processLogoutCallback = async () => {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                // @ts-expect-error
                await navigateToReturnUrl(getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                // @ts-expect-error
                setMessage(result.message)
                break;
            default:
                throw new Error("Invalid authentication result status.");
        }
    }

    const populateAuthenticationState = async () => {
        const authenticated = await authService.isAuthenticated();
        setIsReady(true)
        setAuthenticated(authenticated)
    }

    const getReturnUrl = (state?: any) => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}/${ApplicationPaths.LoggedOut}`;
    }

    const navigateToReturnUrl = (returnUrl: string) => {
        return window.location.replace(returnUrl);
    }

    const action = props.action;
    switch (action) {
        case LogoutActions.Logout:

            logout(getReturnUrl());

            // if (!!window.history.state.state.local) {
            //     this.logout(this.getReturnUrl());
            // } else {
            //     // This prevents regular links to <app>/authentication/logout from triggering a logout
            //     this.setState({ isReady: true, message: "The logout was not initiated from within the page." });
            // }
            break;
        case LogoutActions.LogoutCallback:
            processLogoutCallback();
            break;
        case LogoutActions.LoggedOut:
            setIsReady(true)
            setMessage("You successfully logged out!")
            break;
        default:
            throw new Error(`Invalid action '${action}'`);
    }

    populateAuthenticationState();

    const RenderMessage = (action: string) => {
        if (!isReady) {
            return ""
        }
        if (!!message) {
            return message
        } else {
            switch (action) {
                case LogoutActions.Logout:
                    return ("Processing logout");
                case LogoutActions.LogoutCallback:
                    return ("Processing logout callback");
                case LogoutActions.LoggedOut:
                    return message;
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }
    }

    return  <AppStyle>
        <MainColumn>
            <TileContainer>
                <ContentTile>
                    <ControllerModeContainer>
                        <ColumnLayout>
                            {!!message && message}
                            {RenderMessage(action)}
                        </ColumnLayout>
                    </ControllerModeContainer>
                </ContentTile>
            </TileContainer>
        </MainColumn>
    </AppStyle>

}