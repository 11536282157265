import { LanguageConfiguration, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration";
import { RoundControllerParams } from "../Views/RoundView";
import { db } from "./db";

export interface SettingsStorageItem {
    Id?: number,
    LanguageId: number,
    Params: RoundControllerParams
}

export function DefaultUnitControllerParams() : RoundControllerParams {
    return {
        IntroduceNew: true,
        IntroduceCount: 10,
        RoundSize: 10,

        TimeLimit: 0,
        PromoteOnNth: 1,
        FailOnNth: 1,

        ShowUntilCorrect: true,
        ShuffleOnIncorrect: true,

        SaveResults: true,

        TestDefinitions: [],

        OrderRound: (Item: any[]) => Item
    }
}

export class SettingsState {
    async Set(LanguageId: number, Options: RoundControllerParams) {
        var settingsStorage = await (db.Settings.where('LanguageId').equals(LanguageId).first());

        if (settingsStorage == undefined) {
            await db.Settings.add({
                LanguageId: LanguageId,
                Params: Options
            })
        } else {
            await (db.Settings.update(settingsStorage?.Id, { Params: Options }))
        }

    }

    async Get(LanguageId: number) {
        var settingsStorage = await (db.Settings.where('LanguageId').equals(LanguageId).first());

        if (settingsStorage == undefined) {

            var defaultSettings = {
                LanguageId: LanguageId,
                Params: DefaultUnitControllerParams()
            }

            defaultSettings.Params.OrderRound = undefined


            await db.Settings.add(defaultSettings)

            return defaultSettings
        }

        return settingsStorage
    }
}