import { ZhLanguageItemTypesText, ZhLanguageTestypesText as ZhLanguageTestTypesText, ZhLanguageUnitTypesText } from "./Types";

export type LanguageTextReference = 'Unit' | 'Item' | 'Test'

export type LanguageTextMap = Record<LanguageTextReference, Record<number, string>>

export const ZhLanguageText : LanguageTextMap = {
    "Unit": ZhLanguageUnitTypesText,
    "Item": ZhLanguageItemTypesText,
    "Test": ZhLanguageTestTypesText
}