import { useContext } from "react"
import { DashboardResponse } from "../api"
import { TextStyle } from "../App"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"



export const ScoreCardView: React.FC = (props) => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['score-card'],
        queryFn: () => api.ScoreCard().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity

    })


    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error?.message}</>
    if (data == undefined) return <>An error has occurred: { }</>


    return <>
        <ColumnLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
            <TextStyle size={1.5}>Score Card</TextStyle>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Today</TextStyle>
                <TextStyle>{data.todayScore.toFixed(2)}</TextStyle>
                <TextStyle weight='bold'>Period</TextStyle>
                <TextStyle>{data.periodScore.toFixed(2)}</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Daily Goals</TextStyle>
                <TextStyle>{data.dailyGoalMet ? '🟢' : '🔴'}</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle size={0.75} weight='bold'>New</TextStyle>
                <TextStyle size={0.75}>{data.dailyGoalProgress.newItem} / {data.dailyGoals.newItem}</TextStyle>
                <TextStyle size={0.75} weight='bold'>Review</TextStyle>
                <TextStyle size={0.75}>{data.dailyGoalProgress.review} / {data.dailyGoals.review}</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle size={0.75} weight='bold'>Sentences</TextStyle>
                <TextStyle size={0.75}>{data.dailyGoalProgress.sentenceRead} / {data.dailyGoals.sentenceRead}</TextStyle>
            </RowLayout>

        </ColumnLayout>
    </>



}

export const StudyDashboardTile: React.FC = (props) => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['dashboard'],
        queryFn: () => api.Dashboard().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity

    })


    const { isPending: isPendingReviewsDueToday, error: errorReviewsDueToday, data: dataReviewsDueToday } = useQuery({
        queryKey: ['reviews-due-today'],
        queryFn: () => api.ReviewsDueToday().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity

    })
    if (isPending || isPendingReviewsDueToday) return <>Loading...</>
    if (error || errorReviewsDueToday) return <>An error has occurred: {error?.message ?? errorReviewsDueToday?.message}</>
    if (data == undefined || dataReviewsDueToday == undefined) return <>An error has occurred: { }</>


    return <>
        <ColumnLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Due</TextStyle>
                <TextStyle>{dataReviewsDueToday}</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Minutes studied</TextStyle>
                <TextStyle>{data.minutesStudiedToday}</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                {data.studiedTodayCheck && <TextStyle>🟢</TextStyle>}
                {!data.studiedTodayCheck && data.studiedYesterdayCheck && <TextStyle>🟡</TextStyle>}
                {!data.studiedTodayCheck && !data.studiedYesterdayCheck && <TextStyle>🔴</TextStyle>}
            </RowLayout>
            {data.studiedYesterdayCheck &&
                <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <TextStyle weight='bold'>Current Streak</TextStyle>
                    <TextStyle>{data.currentStreak}</TextStyle>
                </RowLayout>
            }
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Longest Streak</TextStyle>
                <TextStyle>{data.longestStreak}</TextStyle>
            </RowLayout>
        </ColumnLayout>
    </>



}