import styled from "styled-components"
import { TextStyle } from "../../App"
import { ColumnLayout, RowLayout } from "../../Styles/Styles"
import { DisplayItemAboveFoldProps, DisplayItemBelowFoldProps, NewDisplayItemAboveFoldProps, NewDisplayItemBelowFoldProps } from "../../Types/DisplayItem"
import { PlayTextAudio } from "../PlayTextAudio"
import { AudioRecorderInterface } from "./NewZhBaseUnit"
import { useContext, useState } from "react"
import { StyledButton } from "../ModeTile"
import { ApiContext } from "../../Contexts/Api/ApiContext"
import { UnwrapApiCall } from "../../Contexts/Api/DefaultApiContext"
import React from "react"
import { OptionsIcon } from "../../Icons/OptionsIcon"
import { LoadPrimarySentenceTextDisplay } from "../ItemExamplesView"

export type ZhCompositeUnitType = { Id: string, Target: string, Primary: string }

const StyledInput = styled.input`
    height: 32px;
    font-size: 1rem;
    font-family: Consolas;
    border-radius: 16px;
    border: none;
    background-color: #F5EEE6;
    padding-left: 12px;
`
export const NewZhCompositeUnitSentenceBaseAboveFold: React.FC<NewDisplayItemAboveFoldProps> = props => {

    const api = useContext(ApiContext)
    const [translation, setTranslation] = useState("")
    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const [correct, setCorrect] = useState(false)
    const [halfCorrect, setHalfCorrect] = useState(false)
    const [tries, setTries] = useState(0)

    const inputRef = React.createRef<HTMLInputElement>()

    const Evaluate = () => {
        setLoaded(false)
        setLoading(true)
        setHalfCorrect(false)
        inputRef.current?.blur()
        api.EvaluateTranslation(props.Data.properties['text'], translation).then(UnwrapApiCall).then(result => {
            setLoading(false)
            setLoaded(true)

            if (tries < 2 && (result == 0 || result == 1)) {
                setTries(tries + 1)
                setCorrect(false)
                if (result == 1) {
                    setHalfCorrect(true)
                }
            } else if (result == 2) {
                setCorrect(true)
                props.OnAnswer!!(true)
            } else {
                setCorrect(false)
                props.OnAnswer!!(false)
            }
        })
    }

    return <>
        <ColumnLayout>
            <TextStyle size={2}>{props.Data.properties['text']}</TextStyle>

            {props.ShowAnswerInterface && <>
                <StyledInput autoComplete="off" disabled={loading} ref={inputRef} autoFocus placeholder="translate" type="text" onKeyDown={(e) => { if (e.key == "Enter") Evaluate() }} onChange={(e) => { setTranslation(e.target.value) }} />
                <StyledButton disabled={loading} onClick={Evaluate}>Submit</StyledButton>
                {loading && <TextStyle>Loading...</TextStyle>}
                {loaded && correct == false && !halfCorrect && <TextStyle>❌ Incorrect ❌</TextStyle>}
                {loaded && halfCorrect && <TextStyle>🔴 Almost 🔴</TextStyle>}
            </>}
        </ColumnLayout>
    </>
}

export const NewZhCompositeUnitSentenceBaseBelowFold: React.FC<NewDisplayItemBelowFoldProps> = props => {

    const [displayPrimaryText, setDisplayPrimaryText] = useState(false)

    return <>
        <ColumnLayout>
            <RowLayout style={{ alignItems: "center" }}>
                <PlayTextAudio Text={props.Data.properties['text']} />
                <div onClick={() => setDisplayPrimaryText(!displayPrimaryText)}>
                    <OptionsIcon />
                </div>
            </RowLayout>
            {displayPrimaryText && <LoadPrimarySentenceTextDisplay sentenceRef={props.Data.item} />}
        </ColumnLayout>
    </>
}

export const ZhCompositeDictationTestAboveFold: React.FC<NewDisplayItemAboveFoldProps> = props => {

    return <>
        <TextStyle size={2} weight='bold'>{props.Data.properties['text']}</TextStyle>

        {props.ShowAnswerInterface &&
            <>
                <ColumnLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <PlayTextAudio Text={props.Data.properties['text']} />
                        <AudioRecorderInterface Unit={props.Data.properties['text']} OnAnswer={props.OnAnswer!!} />
                    </RowLayout>
                </ColumnLayout>
            </>
        }


        {/* <RowLayout>
            <StyledButton onClick={() => props.OnAnswer!!(true)}>Correct</StyledButton>
            <StyledButton onClick={() => props.OnAnswer!!(false)}>Incorrect</StyledButton>
        </RowLayout> */}
    </>
}