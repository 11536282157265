/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ProcessedSentence } from '../models/ProcessedSentence';
import { HttpFile } from '../http/http';

export class ProcessedParagraph {
    'ref'?: string;
    'fullText'?: string;
    'sentences': Array<ProcessedSentence>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "ref",
            "baseName": "Ref",
            "type": "string",
            "format": ""
        },
        {
            "name": "fullText",
            "baseName": "FullText",
            "type": "string",
            "format": ""
        },
        {
            "name": "sentences",
            "baseName": "Sentences",
            "type": "Array<ProcessedSentence>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ProcessedParagraph.attributeTypeMap;
    }

    public constructor() {
    }
}

