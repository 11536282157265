/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PartialTextContainer } from '../models/PartialTextContainer';
import { PartialTextContainerStructure } from '../models/PartialTextContainerStructure';
import { PartialTextNode } from '../models/PartialTextNode';
import { HttpFile } from '../http/http';

export class PartialTextStructureType {
    'structure': PartialTextContainerStructure;
    'primary'?: string;
    'target': string;
    'type': PartialTextStructureTypeTypeEnum;
    'label'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "structure",
            "baseName": "Structure",
            "type": "PartialTextContainerStructure",
            "format": ""
        },
        {
            "name": "primary",
            "baseName": "Primary",
            "type": "string",
            "format": ""
        },
        {
            "name": "target",
            "baseName": "Target",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "Type",
            "type": "PartialTextStructureTypeTypeEnum",
            "format": ""
        },
        {
            "name": "label",
            "baseName": "Label",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PartialTextStructureType.attributeTypeMap;
    }

    public constructor() {
    }
}


export enum PartialTextStructureTypeTypeEnum {
    Node = 'Node'
}

