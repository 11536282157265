import { ItemTypeTestConfigurationMap, ZhLanguageItemTypes, ZhLanguageTestTypes } from "../Types";

export const ZhItemTypeTestConfiguration: ItemTypeTestConfigurationMap = {
    [ZhLanguageItemTypes.Radical]: {
        PrimaryTestDefinitionId: ZhLanguageTestTypes.GlyphToMeaning,
        TestDefinitions: [ZhLanguageTestTypes.GlyphToMeaning, ZhLanguageTestTypes.GlyphToSpokenReading, ZhLanguageTestTypes.MeaningReadingToWrittenGlyph]
    },
    [ZhLanguageItemTypes.Hanzi]: {
        PrimaryTestDefinitionId: ZhLanguageTestTypes.GlyphToMeaning,
        TestDefinitions: [ZhLanguageTestTypes.GlyphToMeaning, ZhLanguageTestTypes.GlyphToSpokenReading, ZhLanguageTestTypes.MeaningReadingToWrittenGlyph]
    },
    [ZhLanguageItemTypes.Word]: {
        PrimaryTestDefinitionId: ZhLanguageTestTypes.GlyphToMeaning,
        TestDefinitions: [ZhLanguageTestTypes.GlyphToMeaning, ZhLanguageTestTypes.GlyphToSpokenReading, ZhLanguageTestTypes.MeaningReadingToWrittenGlyph]
    },
    [ZhLanguageItemTypes.CoreSentence]: {
        PrimaryTestDefinitionId: ZhLanguageTestTypes.CompositeRead,
        TestDefinitions: [ZhLanguageTestTypes.CompositeRead, ZhLanguageTestTypes.CompositeDictation]
    },
    [ZhLanguageItemTypes.SentenceFromText]: {
        PrimaryTestDefinitionId: ZhLanguageTestTypes.CompositeRead,
        TestDefinitions: [ZhLanguageTestTypes.CompositeRead, ZhLanguageTestTypes.CompositeDictation]
    },
}