import { ZhLanguageItemTypes, ZhLanguageUnitTypes } from "../Types";

export type LanguageItemTypeListing = {
    Id: number,
    ItemTypes: number[]
}

export type LanguageUnitItemConfiguration = {
    UnitTypes: number[]
    UnitConfigurations: Record<number, LanguageItemTypeListing>
}

export const ZhUnitConfig: LanguageUnitItemConfiguration = {

    UnitTypes: [ZhLanguageUnitTypes.Base, ZhLanguageUnitTypes.Extra, ZhLanguageUnitTypes.BaseFromTexts, ZhLanguageUnitTypes.Composite, ZhLanguageUnitTypes.FromSingleText, ZhLanguageUnitTypes.SentenceBreakdown],
    UnitConfigurations: {
        [ZhLanguageUnitTypes.Base]: {
            Id: ZhLanguageUnitTypes.Base,
            ItemTypes: [ZhLanguageItemTypes.Radical, ZhLanguageItemTypes.Hanzi, ZhLanguageItemTypes.Word],
        },
        [ZhLanguageUnitTypes.Extra]: {
            Id: ZhLanguageUnitTypes.Extra,
            ItemTypes: [ZhLanguageItemTypes.Hanzi, ZhLanguageItemTypes.Word],
        },
        [ZhLanguageUnitTypes.BaseFromTexts]: {
            Id: ZhLanguageUnitTypes.BaseFromTexts,
            ItemTypes: [ZhLanguageItemTypes.Hanzi, ZhLanguageItemTypes.Word],
        },
        [ZhLanguageUnitTypes.Composite]: {
            Id: ZhLanguageUnitTypes.Composite,
            ItemTypes: [ZhLanguageItemTypes.CoreSentence, ZhLanguageItemTypes.SentenceFromText],
        },

        [ZhLanguageUnitTypes.FromSingleText]: {
            Id: ZhLanguageUnitTypes.FromSingleText,
            ItemTypes: [ZhLanguageItemTypes.Hanzi, ZhLanguageItemTypes.Word, ZhLanguageItemTypes.SentenceFromText],
        },

        [ZhLanguageUnitTypes.SentenceBreakdown]: {
            Id: ZhLanguageUnitTypes.SentenceBreakdown,
            ItemTypes: [ZhLanguageItemTypes.Hanzi, ZhLanguageItemTypes.Word, ZhLanguageItemTypes.SentenceFromText],
        }
    },
}