import { PartialChapter, PartialParagraph, PartialSentence } from "../api";

function IsTerminator(char: string) {
    return (char == "。" || char == "？" || char == "！")
}

function IsQuoteStart(char: string) {
    return (char == "“"
        || char == "《" || char == "〈"
        || char == "「" || char == "『"
        || char == "(" || char == "（ "
        || char == "[" || char == "［" || char == "【"
    )
}

function IsQuoteEnd(char: string) {
    return (
        char == "”"
        || char == "》" || char == "〉"
        || char == "」" || char == "』"
        || char == ")" || char == "） "
        || char == "]" || char == "］" || char == "】"
    )
}

function CreateFragment(text: string, startIndex: number, endIndex: number) {
    const fragment = new PartialSentence()
    fragment.text = text.slice(startIndex, endIndex)
    return fragment
}

function ProcessQuote(text: string) {
    var startIndex = 0
    var currentIndex = 0

    var fragments: PartialSentence[] = []

    while (currentIndex < text.length) {
        if (IsTerminator(text[currentIndex])) {
            fragments.push(CreateFragment(text, startIndex, currentIndex + 1))

            startIndex = currentIndex + 1

        }
        currentIndex++

    }

    if (startIndex == 0 && fragments.length == 0) {
        fragments.push(CreateFragment(text, 0, currentIndex))
    }

    return fragments
}

export function ParseTextIntoPartialChapter(text: string) {


    const pc: PartialChapter = new PartialChapter()
    pc.paragraphs = []

    // normalise line breaks in text.
    const normalised = text.replace(/\r?\n/g, '\n')

    // split texts into chunks of whitespace-separated texts. denotes a paragraph
    const paragraphs = normalised.split("\n\n")

    for (var pg of paragraphs) {
        const t = pg.trim()

        if (t == "") {
            continue;
        }

        const partial = new PartialParagraph()
        partial.fullText = t
        partial.sentences = []



        const lines = t.split("\n")

        // line breaks denote collections of sentences.
        for (var line of lines) {
            var startIndex = 0
            var currentIndex = 0
            var fragmentStartIndex = 0
            var quoteStack: { char: string, index: number }[] = []

            // process each character of the line.
            while (currentIndex < line.length) {

                // if the character is terminating AND not inside a quote context, add sentence fragment to list.
                if (IsTerminator(line[currentIndex]) && quoteStack.length == 0) {

                    if (fragmentStartIndex != startIndex) {
                        partial.sentences.push(CreateFragment(line, fragmentStartIndex, currentIndex + 1))
                    }

                    partial.sentences.push(CreateFragment(line, startIndex, currentIndex + 1))

                    // set parser position to character following terminator.
                    startIndex = currentIndex + 1
                    // reset fragment index position.
                    fragmentStartIndex = startIndex
                }

                // functionality to handle ascii quotes. does not work.
                // if ((quoteStack[quoteStack.length - 1]?.char != '"' && line[currentIndex] == '"')) {

                //     if (fragmentStartIndex != currentIndex) {
                //         partial.sentences.push(CreateFragment(line, fragmentStartIndex, currentIndex))
                //     }

                //     quoteStack.push({ char: line[currentIndex], index: currentIndex })

                // } else if ((quoteStack[quoteStack.length - 1]?.char != '"' && line[currentIndex] == '"')) {

                //     const quoteStart = quoteStack.pop()

                //     partial.sentences.push(CreateFragment(line, quoteStart!!.index, currentIndex + 1))

                //     fragmentStartIndex = currentIndex + 1


                // } else

                if (IsQuoteStart(line[currentIndex])
                ) {
                    // initiate a quote context.
                    // the fragment start index condition indicates that text has occured before the quote has been detected.
                    if (fragmentStartIndex != currentIndex) {
                        const fragment = new PartialSentence()
                        fragment.text = line.slice(fragmentStartIndex, currentIndex)
                        partial.sentences.push(fragment)
                    }

                    quoteStack.push({ char: line[currentIndex], index: currentIndex })

                } else if (IsQuoteEnd(line[currentIndex])) {

                    // exit a quote context.

                    const quoteStart = quoteStack.pop()

                    // process the text inside the quote as unquoted multi-fragment text.
                    // should be made optional by parameter
                    // does not include the quote characters.
                    const fragments = ProcessQuote(line.slice(quoteStart!!.index + 1, currentIndex));
                    fragments.map(f => partial.sentences.push(f));

                    // finally, add the full quoted fragment with quote characters.
                    partial.sentences.push(CreateFragment(line, quoteStart!!.index, currentIndex + 1));
                    fragmentStartIndex = currentIndex + 1

                }

                currentIndex++;
            }

            // this code is executed after the full line text has been iterated over.

            // handles two cases:
            // if no sentences have been added, due to no terminating character or quote contexts detected, add the fragment.
            // if terminating character had been detected, fragment start index == currentindex, set inside code.
            // - case: fragmentstartindex: 0, currentindex: end
            // if the iteration has completed and a dangling fragment is detected without a terminating character, add the fragment. 
            // - case: fragmentstartindex: >0, currentindex: end
            if (fragmentStartIndex != currentIndex) {
                partial.sentences.push(CreateFragment(line, fragmentStartIndex, currentIndex))
            }

            // if a quote context has not been terminated due to mismatched quotes, add the fragment.
            // preferred behaviour over ignoring unclosed text.
            if (quoteStack.length > 0) {
                while (quoteStack.length > 0) {
                    const quoteStart = quoteStack.pop()
                    partial.sentences.push(CreateFragment(line, quoteStart!!.index, currentIndex))
                }
            }

        }

        pc.paragraphs.push(partial)

    }

    return pc
}