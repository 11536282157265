/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class UserGradeState {
    'total': number;
    'progress': number;
    'completed': boolean;
    'grade': number;
    'itemTypeId': number;
    'unitId': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "total",
            "baseName": "Total",
            "type": "number",
            "format": "double"
        },
        {
            "name": "progress",
            "baseName": "Progress",
            "type": "number",
            "format": "double"
        },
        {
            "name": "completed",
            "baseName": "Completed",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "grade",
            "baseName": "Grade",
            "type": "number",
            "format": "double"
        },
        {
            "name": "itemTypeId",
            "baseName": "ItemTypeId",
            "type": "number",
            "format": "double"
        },
        {
            "name": "unitId",
            "baseName": "UnitId",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return UserGradeState.attributeTypeMap;
    }

    public constructor() {
    }
}

