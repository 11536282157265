import { useContext, useState } from "react"
import { TextStyle } from "../App"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { ZhLanguageItemTypes, ZhLanguageItemTypesText, ZhLanguageUnitTypes, ZhLanguageUnitTypesText } from "../Config/Language/Zh/Types"
import { ColumnLayout, InnerTile, RowLayout } from "../Styles/Styles"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { Paginator } from "../Components/Paginator"
import { ItemData, ItemHistory, SentenceSelectionMethod } from "../api"
import { Content } from "antd/es/layout/layout"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { Modal } from "../Components/Modal"
import { ZhUnitTestInterfaceConfig } from "../Config/Language/Zh/Config/UnitTestInterfaceConfig"
import { PracticeItemView } from "./PracticeView"
import dayjs from "dayjs"
import { ExampleSentenceDisplay, ItemExamplesView, LoadPrimarySentenceTextDisplay } from "../Components/ItemExamplesView"
import { AnnotatedTextDisplay } from "../Displays/TextStructureDisplay"
import { PlayTextAudio } from "../Components/PlayTextAudio"
import { OptionsIcon } from "../Icons/OptionsIcon"
import { SentenceReaderView } from "./SentenceReaderView"

export const SentencePageQueryView = () => {

    const [page, setPage] = useState(1)
    const [count, setCount] = useState(10)

    const [showItemData, setShowItemData] = useState(false)
    const [selectionMethod, setSelectionMethod] = useState<SentenceSelectionMethod>(SentenceSelectionMethod.Recent)

    const Show = () => {
        setShowItemData(true)
    }

    return <ContentTile>

        <ColumnLayout>
            <RowLayout>
                <TextStyle>Count</TextStyle>
                <input type="number" value={count} onChange={(e) => setCount(e.target.value as unknown as number)} />
            </RowLayout>
            <RowLayout>
                <TextStyle>Page</TextStyle>
                <input type="number" value={page} onChange={(e) => setPage(e.target.value as unknown as number)} />
            </RowLayout>
            <RowLayout>
                <select value={selectionMethod} onChange={(e) => setSelectionMethod(e.target.value as unknown as SentenceSelectionMethod)}>
                    <option value={SentenceSelectionMethod.Recent}>Recent</option>
                    <option value={SentenceSelectionMethod.NewTodayKnown}>New Today Known</option>
                    <option value={SentenceSelectionMethod.Known}>Known</option>
                    <option value={SentenceSelectionMethod.Nplusone}>N+1</option>
                </select>
            </RowLayout>
            <StyledButton onClick={Show}>Search</StyledButton>
        </ColumnLayout>
        {showItemData && selectionMethod == SentenceSelectionMethod.Recent && <LoadSentencePageDisplay count={count} page={page} SetPage={setPage} />}
        {showItemData && selectionMethod == SentenceSelectionMethod.Known && <LoadSentenceSelectionDisplay selectionMethod={selectionMethod} count={count} />}
        {showItemData && selectionMethod == SentenceSelectionMethod.Nplusone && <LoadSentenceSelectionDisplay selectionMethod={selectionMethod} count={count} />}
        {showItemData && selectionMethod == SentenceSelectionMethod.NewTodayKnown && <LoadSentenceSelectionDisplay selectionMethod={selectionMethod} count={count} />}

    </ContentTile>
}

const LoadSentenceSelectionDisplay: React.FC<{ selectionMethod: SentenceSelectionMethod, count: number }> = props => {
    const api = useContext(ApiContext)

    const [refreshToken, setRefreshToken] = useState(0)

    const { isPending, error, data } = useQuery({
        queryKey: ["sentenceSelection", props.selectionMethod, props.count, refreshToken],
        queryFn: () => {
            return api.SentenceSelection({ selectionMethod: props.selectionMethod, count: Number.parseInt(props.count.toString()) }).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ColumnLayout>

            <SentenceReaderView Data={{ Sentences: data }} OnExit={() => { setRefreshToken(refreshToken + 1) }} />
            {/* {data.map((sentence) => {
                return <ExampleSentenceDisplay key={sentence.ref} text={sentence.text} sentenceRef={sentence.ref} segments={sentence.segments} />
            })} */}
            <RowLayout>
                <StyledButton onClick={() => { setRefreshToken(refreshToken + 1) }}>Reload</StyledButton>
            </RowLayout>

        </ColumnLayout>
    </>
}

const LoadSentencePageDisplay: React.FC<{ count: number, page: number, SetPage: (page: number) => void }> = props => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ["sentencePage", props.count, props.page],
        queryFn: () => {
            return api.SentencePage({ count: props.count, page: props.page }).then(UnwrapApiCall)
        },
        staleTime: 0,
        gcTime: 0,
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>


    return <>

        <ColumnLayout>
            <RowLayout style={{ padding: 24 }}>
                <TextStyle>Showing</TextStyle>
                <TextStyle weight="bold">{(props.page - 1) * props.count} - {(props.page) * props.count}</TextStyle>
                <TextStyle>of</TextStyle>
                <TextStyle weight="bold">{data.total}</TextStyle>
                <TextStyle>sentences</TextStyle>
            </RowLayout>


            {data.sentences.length > 0 && <SentenceReaderView Data={{ Sentences: data.sentences }} OnExit={() => {  }} />}

            <RowLayout>
                <StyledButton onClick={() => { props.SetPage(props.page - 1) }}>Previous</StyledButton>
                <StyledButton onClick={() => { props.SetPage(props.page + 1) }}>Next</StyledButton>
            </RowLayout>
        </ColumnLayout >
    </>
}
