import React, { useContext, useState, useEffect } from "react"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ResponseType } from "../Contexts/Api/RootApiContext"
import { SpeakerIcon } from "../Icons/SpeakerIcon"
import { LoadingIcon } from "../Icons/LoadingIcon"

export const PlayTextAudio: React.FC<{ AutoPlay?: boolean, Text: string }> = props => {

    const api = useContext(ApiContext)

    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [play, setPlay] = useState(false)

    const [source, setSource] = useState("")

    const audioRef = React.createRef<HTMLAudioElement>()

    const playAudio = async () => {
        setLoading(true)
        if (source == "") {
            api.GenerateAudio(props.Text).then((resp) => {
                if (resp.responseType == ResponseType.Success) {
                    setPlay(true)
                    setLoading(false)
                    setSource(`${api.Settings?.ApiServer}${resp.object.path}`)
                }
            })
        } else if (source != "") {
            setPlay(true)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.AutoPlay == true) {
            playAudio()
        }
    }, [props.AutoPlay])

    return <>
        <div onClick={playAudio}>
            {play &&
                <audio crossOrigin="anonymous" onError={(e) => { console.log(e); }} onEnded={() => { setPlay(false) }} autoPlay src={source} ref={audioRef} />
            }
            {loading && <LoadingIcon />}
            {!loading && <SpeakerIcon />}
        </div>
    </>
}