import dayjs from "dayjs";
import { title } from "process";
import { ProcessedTextStructure, SegmentationOutput } from "../api";
import { OrderSelectedUnits as OrderSelectedItems, SelectUnitParameters as SelectItemParameters, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration";
import { db } from "../Database/db";
import { HistoryState, ItemHistoryElement, LanguageFundamentalType } from "../Database/HistoryState";
import { ItemHistoryContainer, SegmentItemType, SegmentUnit } from "../Types/ItemHistoryContainer";
import { ProcessedText } from "../Types/ProcessedText";
import { SimpleItemCache as SimpleItemCache } from "./UnitHistoryAccessor";
import { text } from "stream/consumers";
import { Chapter, Paragraph, Sentence, TextRefStructureType, TextRefType, TextStructure } from "../Database/TextStore";
import { ZhLanguageUnitTypes } from "../Types/Zh/LanguageDefinition";

export async function StoreProcessedTextStructure(Label: string, processedText: ProcessedTextStructure) {

    // iterate segments and create item histories for newly id'd units
    // update client item history
    // yield item cache to check for existing textref item histories
    const itemCache = await SimpleItemCache()

    // store text structure in database

    const textStructure = {
        Label: Label,
        Ref: processedText.ref,
        FullText: processedText.fullText,
        Chapters: processedText.chapters.map(c => {
            return {
                Ref: c.ref,
                FullText: c.fullText,
                Label: c.label,
                Paragraphs: c.paragraphs.map(p => {
                    return {
                        Ref: p.ref,
                        FullText: p.fullText,
                        Sentences: p.sentences.map(s => {
                            return {
                                Ref: s.ref,
                                Text: s.text,
                                PrimaryMeaning: s.primaryMeaning,
                                Segments: s.segments
                            }
                        })
                    }
                })
            }
        })
    }

    await Promise.all(textStructure.Chapters.map(async c => {
        if (c.Ref != undefined && c.FullText != undefined) {
            if (!await db.TextRefStore.get({ Ref: c.Ref }))
                await db.TextRefStore.add({ Ref: c.Ref, Target: c.FullText, Type: 'Chapter', Structure: c.Paragraphs })
        }

        // await Promise.all(c.Paragraphs.map(async p => {
        //     if (p.Ref != undefined && p.FullText != undefined) {
        //         if (!await db.TextRefStore.get({ Ref: p.Ref }))
        //             await db.TextRefStore.add({ Ref: p.Ref, Target: p.FullText, Type: 'Paragraph', Structure: p.Sentences })

        //         if (itemCache[p.Ref] == undefined) {
        //             console.log(`text ref ${p.Ref} not found in cache. creating item history`)
        //             await db.ItemHistory.add({
        //                 TestDefinitionId: 0,
        //                 UnitKey: p.Ref,
        //                 FundamentalType: LanguageFundamentalType.Composite,
        //                 UnitTypes: [],
        //                 Introduced: false,
        //                 Unlocked: false,
        //                 LastSeen: undefined,
        //                 Due: undefined,
        //                 Stability: 0,
        //                 Difficulty: 0,
        //                 Reps: 0,
        //                 Lapses: 0,
        //                 LearningState: 0,
        //                 Score: 0,
        //                 Record: []
        //             })
        //         }

        //     }

        //     await Promise.all(p.Sentences.map(async s => {
        //         if (!await db.TextRefStore.get({ Ref: s.Ref }))
        //             await db.TextRefStore.add({ Ref: s.Ref, Target: s.Text, Primary: s.PrimaryMeaning, Type: 'Sentence', Structure: s.Segments })

        //         if (itemCache[s.Ref] == undefined) {
        //             console.log(`text ref ${s.Ref} not found in cache. creating item history`)
        //             await db.ItemHistory.add({
        //                 TestDefinitionId: 0,
        //                 UnitKey: s.Ref,
        //                 FundamentalType: LanguageFundamentalType.Composite,
        //                 UnitTypes: [],
        //                 Introduced: false,
        //                 Unlocked: false,
        //                 LastSeen: undefined,
        //                 Due: undefined,
        //                 Stability: 0,
        //                 Difficulty: 0,
        //                 Reps: 0,
        //                 Lapses: 0,
        //                 LearningState: 0,
        //                 Score: 0,
        //                 Record: []
        //             })
        //         }
        //     }))
        // }))
    }))

    if (textStructure.Ref != undefined && textStructure.FullText != undefined) {
        if (!await db.TextRefStore.get({ Ref: textStructure.Ref }))
            await db.TextRefStore.add({
                Ref: textStructure.Ref,
                Target: textStructure.FullText,
                Type: 'Text',
                Structure: textStructure.Chapters
            })
    }

    await db.TextStructures.add(textStructure)



}

export function ResolveSegments(text: TextRefType): string[] {

    if (text.Type == 'Text') {
        return text.Structure.map(c => {
            return c.Paragraphs.map(p => {
                return p.Sentences.map(s => {
                    return s.Segments
                }).flat()
            }).flat()
        }).flat()
    }

    else if (text.Type == 'Chapter') {
        return text.Structure.map(p => {
            return p.Sentences.map(s => {
                return s.Segments
            }).flat()
        }).flat()
    }

    else if (text.Type == 'Paragraph') {
        return text.Structure.map(s => {
            return s.Segments
        }).flat()
    }

    else if (text.Type == 'Sentence') {
        return text.Structure
    }

    else {
        return []
    }
}

export function WrapStructureAsTextRef(structure: TextStructure | Chapter | Paragraph | Sentence, Type: TextRefStructureType): TextRefType {

    if (Type == 'Text') {
        return {
            Ref: structure.Ref!!,
            Target: (structure as TextStructure).FullText!!,
            Type: 'Text',
            Structure: (structure as TextStructure).Chapters
        }
    }
    else if (Type == 'Chapter') {
        return {
            Ref: structure.Ref!!,
            Target: (structure as Chapter).FullText!!,
            Type: 'Chapter',
            Structure: (structure as Chapter).Paragraphs
        }
    }
    else if (Type == 'Paragraph') {
        return {
            Ref: structure.Ref!!,
            Target: (structure as Paragraph).FullText!!,
            Type: 'Paragraph',
            Structure: (structure as Paragraph).Sentences
        }
    }
    else if (Type == 'Sentence') {
        return {
            Ref: structure.Ref!!,
            Target: (structure as Sentence).Text,
            Primary: (structure as Sentence).PrimaryMeaning,
            Type: 'Sentence',
            Structure: (structure as Sentence).Segments
        }
    } else {
        throw new Error()
    }
}



export function YieldKnownStructures(text: TextRefType, KnownItemCache: { [key: string]: ItemHistoryElement }): TextRefType[] {

    if (text.Type == 'Text') {
        const knownSegments = text.Structure.map(c => YieldKnownStructures(WrapStructureAsTextRef(c, 'Chapter'), KnownItemCache)).flat()
        if (knownSegments.filter(s => s.Type == "Chapter").length == text.Structure.length) {
            return [text, ...knownSegments]
        } else {
            return knownSegments
        }
    } else if (text.Type == 'Chapter') {
        const knownSegments = text.Structure.map(c => YieldKnownStructures(WrapStructureAsTextRef(c, 'Paragraph'), KnownItemCache)).flat()
        if (knownSegments.filter(s => s.Type == "Paragraph").length == text.Structure.length) {
            return [text, ...knownSegments]
        } else {
            return knownSegments
        }
    } else if (text.Type == 'Paragraph') {
        const knownSegments = text.Structure.map(c => YieldKnownStructures(WrapStructureAsTextRef(c, 'Sentence'), KnownItemCache)).flat()
        if (knownSegments.filter(s => s.Type == "Sentence").length == text.Structure.length) {
            return [text, ...knownSegments]
        } else {
            return knownSegments
        }
    }

    else if (text.Type == 'Sentence') {
        if (text.Structure.every((segment) => KnownItemCache[segment] != undefined && KnownItemCache[segment].Introduced)) {
            return [text]
        } else {
            return []
        }
    }

    else {
        return []
    }

}

export async function ItemHistoryFromTextRefStructure(text: TextRefType, Parameters: SelectItemParameters) {
    const BaseNew: ItemHistoryElement[] = []
    const BaseReview: ItemHistoryElement[] = []

    const CompositeNew: ItemHistoryElement[] = []
    const CompositeReview: ItemHistoryElement[] = []

    const added: { [key: string]: boolean } = {}

    const UnlockedItemHistory = (await db.ItemHistory.toArray()).filter(h => h.Unlocked)

    const ItemCache = await SimpleItemCache()

    const ItemTestCache: { [key: string]: { [test: number]: ItemHistoryElement } } = {};
    (UnlockedItemHistory.map(h => {
        if (ItemTestCache[h.UnitKey] == undefined)
            ItemTestCache[h.UnitKey] = {}
        ItemTestCache[h.UnitKey][h.TestDefinitionId] = h
    }))

    const AllSegments = ResolveSegments(text)

    const AddItemTest = (key: string) => {
        // add only if unlocked.
        if (!added[key] && ItemTestCache[key] != undefined) {
            const item = ItemTestCache[key][ZhLanguageConfiguration.PrimaryTestDefinitionId]
            if (!item.Introduced) {
                BaseNew.push(item)
            } else if (item.Introduced && dayjs(item.Due).diff(new Date(), 'day') == 0) {
                BaseReview.push(item)
            }
            added[key] = true
        }
    }

    if (Parameters.OrderBy == OrderSelectedItems.Frequency) {

        const CountMap: { [key: string]: number } = {}
        const FullUnitCountMap: { [key: string]: number } = {}

        AllSegments.map(s => {

            if (ItemCache[s] != undefined) {
                if (FullUnitCountMap[s] == undefined) {
                    FullUnitCountMap[s] = 1
                } else {
                    FullUnitCountMap[s] += 1
                }
            }

            const baseItems = s.split("")

            baseItems.map(b => {
                if (ItemCache[b] != undefined) {
                    if (CountMap[b] == undefined) {
                        CountMap[b] = 1
                    } else {
                        CountMap[b] += 1
                    }
                }
            })
        })

        const sorted = Object.keys(CountMap).sort((a, b) => CountMap[b] - CountMap[a])
        const fullItemsSorted = Object.keys(FullUnitCountMap).sort((a, b) => FullUnitCountMap[b] - FullUnitCountMap[a])

        console.log(fullItemsSorted)

        sorted.map(AddItemTest)

        fullItemsSorted.map(AddItemTest)

    }

    if (Parameters.OrderBy == OrderSelectedItems.Default) {
        AllSegments.map(s => {

            AddItemTest(s)

            const bases = s.split("")

            bases.map(AddItemTest)

        })
    }

    const newNew = BaseNew.slice(0, Parameters.Counts.New[1]).map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            tests.push(ItemTestCache[h.UnitKey][td])
        })
        return tests
    })

    const newReviwe = BaseReview.slice(0, Parameters.Counts.Review).map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            tests.push(ItemTestCache[h.UnitKey][td])
        })
        return tests
    })

    const knownStructures = YieldKnownStructures(text, ItemCache).filter(s => s.Type == 'Paragraph' || s.Type == 'Sentence')
    console.log(knownStructures)

    const compositeNew: ItemHistoryElement[] = []
    const compositeReview: ItemHistoryElement[] = []

    knownStructures.map(s => {
        if (!added[s.Ref] && ItemTestCache[s.Ref] != undefined) {
            const item = ItemTestCache[s.Ref][ZhLanguageConfiguration.PrimaryTestDefinitionId]
            if (!item.Introduced) {
                compositeNew.push(item)
            } else if (item.Introduced && dayjs(item.Due).diff(new Date(), 'day') == 0) {
                compositeReview.push(item)
            }
            added[s.Ref] = true
        }
    })

    const allCompositeNewTests = compositeNew.slice(0, 5).map(h => {
        const tests: ItemHistoryElement[] = [];
        ([0]).map((td: number) => {
            tests.push(ItemTestCache[h.UnitKey][td])
        })
        return tests
    })

    const allCompositeReviewTests = compositeReview.slice(0, 5).map(h => {
        const tests: ItemHistoryElement[] = [];
        ([0]).map((td: number) => {
            tests.push(ItemTestCache[h.UnitKey][td])
        })
        return tests
    })

    return {
        Base: {
            New: newNew,
            Review: newReviwe
        },
        Composite: {
            New: allCompositeNewTests,
            Review: allCompositeReviewTests
        }

    }

}

export async function ProcessedTextFromSegmentationOutput(Label: string, segmentation: SegmentationOutput[]) {

    const unitHistoryCache = await SimpleItemCache()

    const found = []
    const notfound = []
    const foundchars = []
    const notfoundchars = []
    const ihcs = []

    for (const sentence of segmentation) {

        const itemHistoryContainer: ItemHistoryContainer = {
            OriginalText: sentence.input,
            SegmentUnits: []
        }

        for (const unit of sentence.output) {
            const uh = unitHistoryCache[unit.unit]

            const segmentUnit: SegmentUnit = {
                Segment: unit.unit,
                FullItem: undefined,
                ItemType: SegmentItemType.Core,
                BaseItems: []
            }

            if (uh != undefined) {
                found.push(unit.unit)
                segmentUnit.FullItem = uh.UnitKey
            } else {
                if (unit.dictionaryQuery!!.results!!.length > 0) {

                } else {
                }
                notfound.push(unit.unit)
            }

            const charCache: { [key: string]: any } = {}

            unit.unit.split("").map(c => {
                const history = unitHistoryCache[c]

                if (history != undefined && charCache[c] == undefined) {
                    segmentUnit.BaseItems.push(history.UnitKey)
                    charCache[c] = true
                    foundchars.push(c)
                } else {
                    notfoundchars.push(c)
                }

                return false
            })

            itemHistoryContainer.SegmentUnits.push(segmentUnit)

        }
        ihcs.push(itemHistoryContainer)
    }

    const processedText: ProcessedText = {
        Label: Label,
        Date: new Date(),
        Focused: false,
        ItemHistoryContainers: ihcs,
    }

    return processedText


}

export async function ItemHistoryFromProcessedText(text: ProcessedText, Parameters: SelectItemParameters) {
    const New: ItemHistoryElement[] = []
    const Review: ItemHistoryElement[] = []

    const added: { [key: string]: boolean } = {}

    const UnlockedUnitHistory = (await db.ItemHistory.toArray()).filter(h => h.Unlocked)

    const UnitTestCache: { [key: string]: { [test: number]: ItemHistoryElement } } = {};
    (UnlockedUnitHistory.map(h => {
        if (UnitTestCache[h.UnitKey] == undefined)
            UnitTestCache[h.UnitKey] = {}
        UnitTestCache[h.UnitKey][h.TestDefinitionId] = h
    }))



    if (Parameters.OrderBy == OrderSelectedItems.Frequency) {

        const CountMap: { [key: string]: number } = {}
        const FullUnitCountMap: { [key: string]: number } = {}

        text.ItemHistoryContainers.map(c => {
            c.SegmentUnits.map(u => {

                console.log(u)
                if (UnitTestCache[u.FullItem!!] != undefined) {
                    if (FullUnitCountMap[u.FullItem!!] == undefined) {
                        FullUnitCountMap[u.FullItem!!] = 1
                    } else {
                        FullUnitCountMap[u.FullItem!!] += 1
                    }
                }

                u.BaseItems.map(b => {
                    if (UnitTestCache[b] != undefined) {
                        if (CountMap[b] == undefined) {
                            CountMap[b] = 1
                        } else {
                            CountMap[b] += 1
                        }
                    }
                })
            })
        });

        const sorted = Object.keys(CountMap).sort((a, b) => CountMap[b] - CountMap[a])
        const fullUnitsSorted = Object.keys(FullUnitCountMap).sort((a, b) => FullUnitCountMap[b] - FullUnitCountMap[a])

        console.log(fullUnitsSorted)

        sorted.map(b => {
            if (!added[b]) {
                const unit = UnitTestCache[b][ZhLanguageConfiguration.PrimaryTestDefinitionId]
                if (!unit.Introduced) {
                    New.push(unit)
                } else if (unit.Introduced && dayjs(unit.Due).diff(new Date(), 'day') == 0) {
                    Review.push(unit)
                }
                added[b] = true

            }
        })

        fullUnitsSorted.map(b => {
            if (!added[b]) {
                const unit = UnitTestCache[b][ZhLanguageConfiguration.PrimaryTestDefinitionId]
                if (!unit.Introduced) {
                    New.push(unit)
                } else if (unit.Introduced && dayjs(unit.Due).diff(new Date(), 'day') == 0) {
                    Review.push(unit)
                }
                added[b] = true

            }

        })
    }

    if (Parameters.OrderBy == OrderSelectedItems.Default) {
        text.ItemHistoryContainers.map(c => {
            c.SegmentUnits.map(u => {
                u.BaseItems.map(b => {
                    if (UnitTestCache[b] != undefined) {

                        // keep track of duplicates and only add if not previously added
                        if (!added[b]) {
                            const unit = UnitTestCache[b][ZhLanguageConfiguration.PrimaryTestDefinitionId]
                            if (!unit.Introduced) {
                                New.push(unit)
                            } else if (unit.Introduced && dayjs(unit.Due).diff(new Date(), 'day') == 0) {
                                Review.push(unit)
                            }
                            added[b] = true

                        }
                    }
                })
            })
        })
    }

    const newNew = New.map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            tests.push(UnitTestCache[h.UnitKey][td])
        })
        return tests
    })

    const newReviwe = Review.slice(0, Parameters.Counts.Review).map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            tests.push(UnitTestCache[h.UnitKey][td])
        })
        return tests
    })

    return {
        Base: {
            New: newNew,
            Review: newReviwe
        }, Composite: { New: [], Review: [] }

    }
}

