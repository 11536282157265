import React, { useContext, useEffect, useMemo, useState } from "react";
import { ApiContext } from "../Contexts/Api/ApiContext";
import { SetMenu } from "../Loaders/LanguageDefinitionLoader";
import { ColumnLayout, RowLayout } from "../Styles/Styles";
import { TextStyle } from "../App"
import { GenerateTextRequest, ProcessedTextStructure } from "../api";
import { ResponseType } from "../Contexts/Api/RootApiContext";
import { StoreProcessedTextStructure } from "../Utility/ProcessedTextFromSegmentedOutput";
import { GenerateTextParametersView } from "./GenerateTextParametersView";

export const GenerateTextView: React.FC<SetMenu & { OnExit: (...a: any) => any }> = props => {

    enum Mode {
        Settings,
        Loading,
    }

    enum TextDifficulty {
        Beginner,
        Intermediate,
        Advanced,
        Native
    }

    enum TextLength {
        Short,
        Medium,
        Long
    }

    enum TextType {
        Story,
        Analysis,
        Report
    }

    enum GeneratedTextStyle {
        Simple,
        Abstract,
        Poetry,
        Descriptive
    }

    enum TextPromptType {
        Prompt,
        Derive
    }



    const api = useContext(ApiContext)

    const [displayMode, setDisplayMode] = useState(Mode.Settings)

    const [textType, setTextType] = useState(TextType.Story)
    const [difficulty, setDifficulty] = useState(TextDifficulty.Beginner)
    const [textLength, setTextLength] = useState(TextLength.Short)
    const [generatedTextStyle, setGeneratedTextStyle] = useState(GeneratedTextStyle.Simple)
    const [dialoguePref, setDialoguePref] = useState(false)
    const [promptType, setPromptType] = useState(TextPromptType.Prompt)

    const [themePrompt, setThemePrompt] = useState<string>("")
    const [briefDescription, setBriefDescription] = useState<string>("")
    const [deriveFromPrompt, setDeriveFromPrompt] = useState<string>("")

    const [processing, setProcessing] = useState(false)
    const [processed, setProcessed] = useState(false)
    const [processedText, setProcessedText] = useState<ProcessedTextStructure | undefined>(undefined)

    const [title, setTitle] = useState("")

    const [generateTextRequest, setRequest] = useState<GenerateTextRequest | undefined>(undefined)

    const UpdateThemePrompt = (prompt: string) => {
        setThemePrompt(prompt)
        setDeriveFromPrompt("")
    }

    const UpdateDeriveFromPrompt = (prompt: string) => {
        setDeriveFromPrompt(prompt)
        setThemePrompt("")
        setBriefDescription("")
    }

    const SettingsMenu = [
        {
            Label: 'Generate',
            OnClick: async () => { Process() }
        },
        {
            Label: 'Exit',
            OnClick: () => props.OnExit()
        }
    ]

    const LoadingMenu = [
        {
            Label: 'Exit',
            OnClick: () => props.OnExit()
        }
    ]


    useMemo(() => props.SetMenu(SettingsMenu), [])

    const Process = () => {

        setProcessing(true)
        setDisplayMode(Mode.Loading)
        props.SetMenu(LoadingMenu)
    }

    const Generate = async () => {

        generateTextRequest!!.label = title
        const response = await api.GenerateText(generateTextRequest!!)
        if (response.responseType == ResponseType.Success) {
            // await StoreProcessedTextStructure(title, response.object)
        }
    }

    const ApplySettings = (generateTextRequest: GenerateTextRequest) => {
        setProcessing(true)
        setRequest(generateTextRequest)
        setDisplayMode(Mode.Loading)
        props.SetMenu(LoadingMenu)
    }

    useEffect(() => {

        if (processing) {
            Generate().then(() => setProcessed(true)).then(() => setProcessing(false))
        }

    }, [processing])

    return <>
        {displayMode == Mode.Settings && <>

            <RowLayout>
                <TextStyle>Title</TextStyle>
                <input type="text" onChange={e => setTitle(e.target.value)} />
            </RowLayout>
            <GenerateTextParametersView OnComplete={ApplySettings} />
            {/* <ColumnLayout>
                <TextStyle size={2}>Settings</TextStyle>
                <RowLayout>
                    <TextStyle>Title</TextStyle>
                    <input type="text" onChange={e => setTitle(e.target.value)} />
                </RowLayout>
                <RowLayout>
                    <TextStyle>Length</TextStyle>
                    <select defaultValue={TextLength.Short} onChange={(e) => { setTextLength(parseInt(e.target.value)) }}>
                        <option value={TextLength.Short}>Short</option>
                        <option value={TextLength.Medium}>Medium</option>
                        <option value={TextLength.Long}>Long</option>
                    </select>
                </RowLayout>

                <RowLayout>
                    <TextStyle>Type</TextStyle>
                    <select defaultValue={TextType.Story} onChange={(e) => { setTextType(parseInt(e.target.value)) }}>
                        <option value={TextType.Story}>Story</option>
                        <option value={TextType.Analysis}>Analysis</option>
                        <option value={TextType.Report}>Report</option>
                    </select>
                </RowLayout>

                <RowLayout>
                    <TextStyle>Difficulty</TextStyle>
                    <select defaultValue={TextDifficulty.Beginner} onChange={(e) => { setDifficulty(parseInt(e.target.value)) }}>
                        <option value={TextDifficulty.Beginner}>Beginner</option>
                        <option value={TextDifficulty.Intermediate}>Intermediate</option>
                        <option value={TextDifficulty.Advanced}>Advanced</option>
                        <option value={TextDifficulty.Native}>Native</option>
                    </select>
                </RowLayout>

                <RowLayout>
                    <TextStyle>Style</TextStyle>
                    <select defaultValue={GeneratedTextStyle.Simple} onChange={(e) => { setGeneratedTextStyle(parseInt(e.target.value)) }}>
                        <option value={GeneratedTextStyle.Simple}>Simple</option>
                        <option value={GeneratedTextStyle.Descriptive}>Descriptive</option>
                        <option value={GeneratedTextStyle.Abstract}>Abstract</option>
                        <option value={GeneratedTextStyle.Poetry}>Poetry</option>
                    </select>
                </RowLayout>

                <RowLayout>
                    <TextStyle>Focus dialogue:</TextStyle>
                    <input type="checkbox" onChange={(e) => setDialoguePref(e.target.checked)} />
                </RowLayout>

                <RowLayout>
                    <TextStyle>Prompt type:</TextStyle>
                    <select defaultValue={GeneratedTextStyle.Simple} onChange={(e) => { setPromptType(parseInt(e.target.value)) }}>
                        <option value={TextPromptType.Prompt}>User defined</option>
                        <option value={TextPromptType.Derive}>Derive from work</option>
                    </select>
                </RowLayout>

                {promptType == TextPromptType.Prompt && <>
                    <RowLayout>
                        <TextStyle>Theme prompt:</TextStyle>
                        <textarea value={themePrompt} onChange={(e) => UpdateThemePrompt(e.target.value)} />
                    </RowLayout>
                    <RowLayout>
                        <TextStyle>Brief description:</TextStyle>
                        <textarea onChange={(e) => setBriefDescription(e.target.value)} />
                    </RowLayout>
                </>}

                {promptType == TextPromptType.Derive && <>
                    <RowLayout>
                        <TextStyle>Derive from:</TextStyle>
                        <input value={deriveFromPrompt} onChange={(e) => UpdateDeriveFromPrompt(e.target.value)} type="text" />
                    </RowLayout>
                </>}

            </ColumnLayout> */}

        </>}
        {displayMode == Mode.Loading && <>
            {processed == false && <>
                <TextStyle>Generating...</TextStyle>
            </>}
            {processed && <>
                <TextStyle>Generated.</TextStyle>
            </>}
        </>}
    </>
}
