import { useContext, useState } from "react"
import { UnitDefinition } from "../Config/UnitDefinitionMap"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ItemHistoryElement } from "../Database/HistoryState"
import { Data } from "../Types/Interfaces"
import { RoundRecord } from "./RoundView"
import { BasicStatsResponse } from "../api"
import { useQuery } from "@tanstack/react-query"
import { TextStyle } from "../App"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { constants } from "buffer"
import dayjs from "dayjs"

export const BasicStatsLoader: React.FC<Data<{ StartDate: Date, EndDate: Date }>> = props => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['stats#' + props.Data.StartDate.toISOString() + '#' + props.Data.EndDate.toISOString()],
        queryFn: () => api.BasicStats(props.Data.StartDate, dayjs(props.Data.EndDate).add(1, 'day').toDate()).then(UnwrapApiCall),
        gcTime: 0

    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    const response = data!!;


    return <>
        <ColumnLayout>
            <TextStyle>New Items: {response.newItemSummary.distinct}</TextStyle>
            <TextStyle>New Tests: {response.newItemSummary.total}</TextStyle>
            <hr />
            <TextStyle>Success rate: {Math.round((response.roundSummary.success / response.roundSummary.total) * 100)}% ({response.roundSummary.success})</TextStyle>
            <TextStyle>Failed once: {Math.round((response.roundSummary.failedOnce / response.roundSummary.total) * 100)}% ({response.roundSummary.failedOnce})</TextStyle>
            <TextStyle>Failed: {Math.round((response.roundSummary.failed / response.roundSummary.total) * 100)}% ({response.roundSummary.failed})</TextStyle>
            <TextStyle>Total tests: {response.roundSummary.total}</TextStyle>
        </ColumnLayout>


    </>
}
export const StatsView: React.FC = props => {

    const [startDate, setStartDate] = useState(new Date(((new Date()).toDateString())))
    const [endDate, setEndDate] = useState(new Date(((new Date()).toDateString())))


    return <>
            <ColumnLayout>
                <RowLayout>
                    <TextStyle>Start Date</TextStyle>
                    <input type='date' value={dayjs(startDate).format('YYYY-MM-DD')} onChange={e => setStartDate(new Date(e.target.value))} />
                </RowLayout>
                <RowLayout>
                    <TextStyle>End Date</TextStyle>
                    <input type='date' value={dayjs(endDate).format('YYYY-MM-DD')} onChange={e => setEndDate(new Date(e.target.value))} />
                </RowLayout>
                <RowLayout>
                    <BasicStatsLoader Data={{ StartDate: startDate, EndDate: endDate }} />
                </RowLayout>
            </ColumnLayout>

    </>
}