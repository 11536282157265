/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ItemData {
    'type': string;
    'keys': any | null;
    'types': any | null;
    'metadata': any | null;
    'properties': any | null;
    'item': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "keys",
            "baseName": "keys",
            "type": "any",
            "format": ""
        },
        {
            "name": "types",
            "baseName": "types",
            "type": "any",
            "format": ""
        },
        {
            "name": "metadata",
            "baseName": "metadata",
            "type": "any",
            "format": ""
        },
        {
            "name": "properties",
            "baseName": "properties",
            "type": "any",
            "format": ""
        },
        {
            "name": "item",
            "baseName": "item",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ItemData.attributeTypeMap;
    }

    public constructor() {
    }
}

