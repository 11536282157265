/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ProcessedTextStructure2Structure } from '../models/ProcessedTextStructure2Structure';
import { HttpFile } from '../http/http';

export class ProcessedTextStructure2 {
    'label'?: string;
    'type': string;
    'ref'?: string;
    'target'?: string;
    'primary'?: string;
    'structure': ProcessedTextStructure2Structure;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "label",
            "baseName": "Label",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "Type",
            "type": "string",
            "format": ""
        },
        {
            "name": "ref",
            "baseName": "Ref",
            "type": "string",
            "format": ""
        },
        {
            "name": "target",
            "baseName": "Target",
            "type": "string",
            "format": ""
        },
        {
            "name": "primary",
            "baseName": "Primary",
            "type": "string",
            "format": ""
        },
        {
            "name": "structure",
            "baseName": "Structure",
            "type": "ProcessedTextStructure2Structure",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ProcessedTextStructure2.attributeTypeMap;
    }

    public constructor() {
    }
}

