/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DailyGoal } from '../models/DailyGoal';
import { DailyScoreRecord } from '../models/DailyScoreRecord';
import { HttpFile } from '../http/http';

export class ScoreCard {
    'todayScore': number;
    'dailyGoalMet': boolean;
    'dailyGoalProgress': DailyGoal;
    'dailyGoals': DailyGoal;
    'dailyScores': Array<DailyScoreRecord>;
    'periodScore': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "todayScore",
            "baseName": "todayScore",
            "type": "number",
            "format": "double"
        },
        {
            "name": "dailyGoalMet",
            "baseName": "dailyGoalMet",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "dailyGoalProgress",
            "baseName": "dailyGoalProgress",
            "type": "DailyGoal",
            "format": ""
        },
        {
            "name": "dailyGoals",
            "baseName": "dailyGoals",
            "type": "DailyGoal",
            "format": ""
        },
        {
            "name": "dailyScores",
            "baseName": "dailyScores",
            "type": "Array<DailyScoreRecord>",
            "format": ""
        },
        {
            "name": "periodScore",
            "baseName": "periodScore",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return ScoreCard.attributeTypeMap;
    }

    public constructor() {
    }
}

