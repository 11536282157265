/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class AnswerRecord {
    'record': AnswerRecordRecordEnum;
    'timeToAnswer': number;
    'timeAnswered': Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "record",
            "baseName": "Record",
            "type": "AnswerRecordRecordEnum",
            "format": ""
        },
        {
            "name": "timeToAnswer",
            "baseName": "TimeToAnswer",
            "type": "number",
            "format": "double"
        },
        {
            "name": "timeAnswered",
            "baseName": "TimeAnswered",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return AnswerRecord.attributeTypeMap;
    }

    public constructor() {
    }
}


export enum AnswerRecordRecordEnum {
    Success = 'Success',
    Failure = 'Failure',
    Override = 'Override'
}

