import { useContext, useEffect, useState } from "react";
import { AppCallbacks, TextStyle } from "../App";
import { LanguageConfiguration, UpdateUnitState } from "../Config/LanguageDefinitionConfiguration";
import { ItemHistoryElement } from "../Database/HistoryState";
import { Data } from "../Types/Interfaces";
import { LanguageUnitDefinition } from "../Types/LanguageDefinition";
import { ProcessedText } from "../Types/ProcessedText";
import { RoundRecord } from "../Views/RoundView";
import { DefaultUnitControllerParams, SettingsState } from "../Database/SettingsState";
import { db } from "../Database/db";
import { ResponseType } from "../Contexts/Api/RootApiContext";
import { ApiContext } from "../Contexts/Api/ApiContext";
import { LanguageInstanceView } from "../Views/LanguageInstanceView";
import { ContentTile } from "../Components/ModeTile";
import { UnitControllerMode } from "./LanguageDefinitionLoader";
import { TextRefMap, TextRefType } from "../Database/TextStore";

export const LanguageInstanceLoader: React.FC<Data<LanguageConfiguration<any>> & AppCallbacks> = props => {

    const api = useContext(ApiContext)

    const [extraLdu, setExtraLdu] = useState<any | undefined>(undefined)

    const [roundRecords, setRoundRecords] = useState<RoundRecord[] | undefined>([])
    const [history, setHistory] = useState<ItemHistoryElement[] | undefined>(undefined)
    const [texts, setTexts] = useState<ProcessedText[] | undefined>(undefined)
    const [textRefMap, setTextRefMap] = useState<TextRefMap>({})
    const [unitParams, setUnitParams] = useState(DefaultUnitControllerParams())

    const [loaded, setLoaded] = useState(false)

    const [mode, setMode] = useState(UnitControllerMode.Home)


    unitParams.OrderRound = (item) => item

    useEffect(() => {

        let active = true;

        // (new SettingsState().Get(1)).then(settings => { setUnitParams(settings.Params) }).finally(() => setLoaded(true))

        // if (!loaded && active) {
        //     api.AppUnits().then((resp) => {
        //         if (resp.responseType == ResponseType.Success) {
        //             setExtraLdu(resp.object.units)
        //             UpdateUnitState(props.Data, resp.object.units)
        //         }
        //     })
        //         .then(() => { return db.TextRefStore.toArray() }).then((textRefs: TextRefType[]) => {
        //             const TextRefMap: TextRefMap = {}
        //             textRefs.map(tr => TextRefMap[tr.Ref] = tr)
        //             setTextRefMap(TextRefMap)

        //         })
        //         .then(() => { return (new SettingsState()).Get(props.Data.Id) }).then((settings => { setUnitParams(settings.Params) }))
        //         .finally(() => {
        //             console.log("loaded lang instance")
        //             setLoaded(true)
        //         })
        // }

        setLoaded(true)

        return () => {
            active = false
        }
    }, [api, props, loaded])

    const Reload = (ControllerMode: UnitControllerMode) => {
        setLoaded(false)
        setMode(ControllerMode)
    }

    return <>
        {loaded && <>
            <LanguageInstanceView
                Data={{
                    LanguageDefinitionConfiguration: props.Data,
                    // ExtraUnitsDefinition: extraLdu,
                    // TextRefMap: textRefMap,
                    Params: unitParams,
                    // RoundRecords: roundRecords!!,
                }}
                State={{
                    ControllerMode: mode,
                    Locked: false,
                    Loaded: loaded,
                    UseSubUnit: false,
                    SubUnitId: -1,
                    RetryRound: false,
                    RoundRecordId: -1
                }}
                SetMode={setMode}
                SetBySubUnit={(defn) => { }}
                SetByRoundRecord={(rr) => { }}
                ClearRoundRecord={() => { }}
                ClearSubUnit={() => { }}
                Reload={Reload}
                OnEnter={props.OnEnter}
                SetMenu={props.SetMenu}
                SetPreamble={props.SetPreamble}
                OnExit={props.OnExit}
            />

        </>}

        {(!loaded) && <ContentTile>
            {/* <TextStyle size={8} weight='bold'>{props.Data.DisplayLabel}</TextStyle>
            <TextStyle size={3} weight='bold' case='uppercase'>{props.Data.NameLabel}</TextStyle> */}

            <TextStyle>Loading...</TextStyle>
        </ContentTile>}
    </>
}